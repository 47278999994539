<div class='view-wrapper' style='margin: 30px 0' *ngIf='_state.group'>
  <div style='margin: 15px'>
    <div class='table-toolbar'>
      <button mat-stroked-button color="primary" style='margin-right: 12px' (click)='openGroupHandleDialog(_state.group)'>
        Upravit skupinu
        <mat-icon class='oe-primary-icon' fontIcon="edit" style='margin-right: 6px'></mat-icon>
      </button>

      <button mat-stroked-button color="primary" (click)='openDeleteDialog(_state.group)'>
        Smazat skupinu
        <mat-icon class='oe-primary-icon' fontIcon="delete" style='margin-right: 6px'></mat-icon>
      </button>
    </div>
    <mat-card>
      <div class='m-3'>
        <div class='odd-row'><h5>Jméno</h5>{{ _state.group.name }}</div>
        <div class='even-row'><h5>Poznámka</h5>{{ _state.group.note }}</div>
      </div>
    </mat-card>
  </div>

  <div style='margin: 30px 15px; min-width: max-content'>
    <div class='table-toolbar'>
      <button mat-stroked-button color="primary" (click)='openAddServiceDialog()'>
        Přiřadit službu
        <mat-icon class='oe-primary-icon' fontIcon="add" style='margin-right: 6px'></mat-icon>
      </button>
    </div>
    <oe-table
      *ngIf='tableSource'
      [data]='tableSource'
      (rowEdit)='openServiceHandleDialog($event)'
      [hiddenColumns]="['type', 'id']"
      (rowDelete)='openServiceDeleteDialog($event)'
      (detailOpened)='_router.navigate([ERoutes.Main, ERoutes.Heating_Branch_Management, $event.id])'
      [pageSize]='10'>
    </oe-table>
  </div>
</div>
