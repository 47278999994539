import { JsonPipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { IConsumptionPoint } from '../../interfaces/consumption-point.interface';
import { ICatalogue } from '../../interfaces/response.interface';
import { EToastType } from '../../interfaces/toast.interface';
import { ConsumptionPointApiService } from '../../services/consumption-point-api.service';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';
import { OeConsumptionPointHandleDialogComponent } from '../oe-consumption-point-handle-dialog/oe-consumption-point-handle-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';

@Component({
  standalone: true,
  selector: 'oe-consumption-point-detail',
  providers: [HttpClient],
  templateUrl: './oe-consumption-point-detail.component.html',
  styleUrls: ['./oe-consumption-point-detail.component.scss'],
  imports: [MatCardModule, NgIf, MatButton, MatIcon, JsonPipe],
})
export class OeConsumptionPointDetailComponent {
  // INJECTS
  private _consumptionPoint = inject(ConsumptionPointApiService);
  private _router = inject(Router);
  private _dialog = inject(MatDialog);
  private _toast = inject(ToasterService);
  public _state = inject(StateService);

  // VARIABLES
  public elDistributors!: ICatalogue[];
  public gasDistributors!: ICatalogue[];
  public consumptionPoint!: IConsumptionPoint;

  constructor() {
    this.getConsumptionPoint();
  }

  private getConsumptionPoint() {
    const routes = this._router.url.split('/');
    const consumptionPointId = routes[routes.indexOf(ERoutes.Consumption_Point_Management) + 1];
    this._consumptionPoint
      .getConsumptionPoint(consumptionPointId)
      .pipe(take(1))
      .subscribe(point => {
        this._state.consumptionPoint = point;
        this.consumptionPoint = point;

        this._consumptionPoint
          .getGasDistributors()
          .pipe(take(1))
          .subscribe(response => {
            this.gasDistributors = response.list;
            if (this.gasDistributors.find(d => d.key === this.consumptionPoint.gasDistributor)) {
              this.consumptionPoint.gasDistributor = this.gasDistributors.find(
                d => d.key === this.consumptionPoint.gasDistributor
              )?.value as string;
            }
          });

        this._consumptionPoint
          .getElectricityDistributors()
          .pipe(take(1))
          .subscribe(response => {
            this.elDistributors = response.list;
            if (this.elDistributors.find(d => d.key === this.consumptionPoint.electricityDistributor)) {
              this.consumptionPoint.electricityDistributor = this.elDistributors.find(
                d => d.key === this.consumptionPoint.electricityDistributor
              )?.value as string;
            }
          });
      });
  }

  public openDeleteConfirmDialog(consumptionPoint: IConsumptionPoint) {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result !== undefined) {
          this._consumptionPoint
            .deleteConsumptionPoint(consumptionPoint.consumptionPointId as string)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this._toast.open(EToastType.Success, 'Odběrné místo bylo úspěšně smazáno.');
                void this._router.navigate([ERoutes.Main, ERoutes.Consumption_Point_Management]);
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Odběrné místo nebylo možné smazat.');
              },
            });
        }
      });
  }

  public openConsumptionPointHandleDialog(consumptionPoint: IConsumptionPoint) {
    this._dialog
      .open(OeConsumptionPointHandleDialogComponent, {
        height: '500px',
        width: '780px',
        data: consumptionPoint,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._consumptionPoint
            .editConsumptionPoint({ ...result, consumptionPointId: consumptionPoint.consumptionPointId })
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.getConsumptionPoint();
                this._toast.open(EToastType.Success, 'Odběrné místo bylo úspěšně upraveno.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Odběrné místo nebylo možné upravit.');
              },
            });
        }
      });
  }
}
