import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { IBoxDevice } from '../../interfaces/box-service.interface';

@Component({
  standalone: true,
  selector: 'oe-spotbox-handle-dialog',
  providers: [HttpClient],
  templateUrl: './oe-spotbox-handle-dialog.component.html',
  styleUrls: ['./oe-spotbox-handle-dialog.component.scss'],
  imports: [MatButtonModule, MatIconModule, MatFormField, MatInput, MatLabel, ReactiveFormsModule, FormsModule],
})
export class OeSpotboxHandleDialogComponent {
  // INJECTS
  public data = inject(MAT_DIALOG_DATA);
  public dialogRef = inject(MatDialogRef);

  // VARIABLES
  public spotbox: IBoxDevice = {
    name: this.data?.name || '',
    note: this.data?.note || '',
    deviceId: this.data?.deviceId || '',
    consumptionPoint: this.data?.consumptionPoint || '',
    status: this.data?.status || '',
  };
}
