import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { STANDARD_OE_API_URL } from '../constants/standard-oe-api.constant';
import { IChartData, IDevice, IDeviceAvailability, IDeviceDetail, IDeviceEvent } from '../interfaces/device.interface';
import { IListResponse, IRecordsResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class DeviceApiService {
  // INJECTS
  private _http = inject(HttpClient);

  public getDeviceList(aggregatorId: string) {
    return this._http.post<IListResponse<IDevice>>(`${STANDARD_OE_API_URL}/device/get-aggregator-devices`, {
      aggregatorId,
    });
  }

  public getDeviceChartData(deviceId: string) {
    return this._http.post<IChartData>(`${STANDARD_OE_API_URL}/device/get-device-chart-data`, {
      deviceId,
    });
  }

  public getDeviceDetail(deviceId: string) {
    return this._http.post<IDeviceDetail>(`${STANDARD_OE_API_URL}/device/get-device`, { deviceId });
  }

  public activatePower(deviceId: string, active: boolean) {
    return this._http.post(`${STANDARD_OE_API_URL}/device/activate-power`, { deviceId: deviceId, enable: active });
  }

  public getOwnerDevices() {
    return this._http.post<IListResponse<IDevice>>(`${STANDARD_OE_API_URL}/device/get-owner-devices`, {});
  }

  public getDeviceEvents(deviceId: string) {
    return this._http.post<IListResponse<IDeviceEvent>>(`${STANDARD_OE_API_URL}/device/get-device-events`, {
      deviceId: deviceId,
    });
  }

  public getDeviceAvailability(deviceId: string, from: Date, to: Date) {
    return this._http.post<IRecordsResponse<IDeviceAvailability>>(
      `${STANDARD_OE_API_URL}/device-av/get-device-availability`,
      {
        deviceId: deviceId,
        from: dayjs(from).format('YYYY-MM-DD') + 'T10:33:43.284Z',
        to: dayjs(to).format('YYYY-MM-DD') + 'T10:33:43.284Z',
      }
    );
  }

  public addDeviceAvailability(deviceId: string, from: Date, to: Date, type: 'available' | 'notAvailable') {
    return this._http.post(`${STANDARD_OE_API_URL}/device-av/add-device-availability`, {
      deviceId: deviceId,
      from: dayjs(new Date(from)).format('YYYY-MM-DD') + 'T15:38:21.631Z',
      to: dayjs(new Date(to)).format('YYYY-MM-DD') + 'T15:38:21.631Z',
      type: type,
    });
  }

  public editDeviceAvailability(id: string, from: Date, to: Date, type: 'available' | 'notAvailable') {
    return this._http.post(`${STANDARD_OE_API_URL}/device-av/edit-device-availability`, {
      id: id,
      from: dayjs(new Date(from)).format('YYYY-MM-DD') + 'T' + dayjs(new Date(from)).format('HH:mm:ss') + '.300Z',
      to: dayjs(new Date(to)).format('YYYY-MM-DD') + 'T' + dayjs(new Date(to)).format('HH:mm:ss') + '.300Z',
      type: type,
    });
  }

  public removeDeviceAvailability(deviceId: string) {
    return this._http.post(`${STANDARD_OE_API_URL}/device-av/remove-device-availability`, {
      id: deviceId,
    });
  }
}
