import { NgForOf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { take } from 'rxjs';
import { ICompany } from '../../interfaces/company.interface';
import { IConsumptionPoint } from '../../interfaces/consumption-point.interface';
import { ICatalogue } from '../../interfaces/response.interface';
import { CompanyApiService } from '../../services/company-api.service';
import { ConsumptionPointApiService } from '../../services/consumption-point-api.service';

@Component({
  standalone: true,
  selector: 'oe-consumption-point-handle-dialog',
  providers: [HttpClient],
  templateUrl: './oe-consumption-point-handle-dialog.component.html',
  styleUrls: ['./oe-consumption-point-handle-dialog.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormField,
    FormsModule,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
  ],
})
export class OeConsumptionPointHandleDialogComponent implements OnInit {
  // INJECTS
  public data = inject(MAT_DIALOG_DATA);
  public dialogRef = inject(MatDialogRef);
  public _company = inject(CompanyApiService);
  public _consumptionPoint = inject(ConsumptionPointApiService);

  // VARIABLES
  public companyList!: ICompany[];
  public elDistributors!: ICatalogue[];
  public gasDistributors!: ICatalogue[];
  public consumptionPoint: IConsumptionPoint = {
    companyId: this.data?.companyId || '',
    ean: this.data?.ean || '',
    eic: this.data?.eic || '',
    address: this.data?.address || '',
    gps: this.data?.gps || '',
    electricityDistributor: this.data?.electricityDistributor || '',
    gasDistributor: this.data?.gasDistributor || '',
  };

  ngOnInit() {
    this._company
      .getCompanyList()
      .pipe(take(1))
      .subscribe(companies => {
        this.companyList = companies.list;
      });

    this._consumptionPoint
      .getElectricityDistributors()
      .pipe(take(1))
      .subscribe(distributors => {
        this.elDistributors = distributors.list;
        if (this.elDistributors.find(d => d.value === this.consumptionPoint.electricityDistributor)) {
          this.consumptionPoint.electricityDistributor = this.elDistributors.find(
            d => d.value === this.consumptionPoint.electricityDistributor
          )?.key as string;
        }
      });

    this._consumptionPoint
      .getGasDistributors()
      .pipe(take(1))
      .subscribe(distributors => {
        this.gasDistributors = distributors.list;
        if (this.gasDistributors.find(d => d.value === this.consumptionPoint.gasDistributor)) {
          this.consumptionPoint.gasDistributor = this.gasDistributors.find(
            d => d.value === this.consumptionPoint.gasDistributor
          )?.key as string;
        }
      });
  }
}
