import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CalendarCommonModule } from 'angular-calendar';
import * as dayjs from 'dayjs';
import { EDeviceAvailability } from '../../interfaces/device.interface';

@Component({
  standalone: true,
  selector: 'oe-calendar-event-dialog',
  providers: [HttpClient],
  templateUrl: './oe-calendar-event-dialog.component.html',
  styleUrls: ['./oe-calendar-event-dialog.component.scss'],
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    CalendarCommonModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    FormsModule,
  ],
})
export class OeCalendarEventDialogComponent implements OnInit {
  // INJECTS
  public data = inject(MAT_DIALOG_DATA);
  public dialogRef = inject(MatDialogRef);

  // VARIABLES
  public dateFrom!: Date | null;
  public dateTo!: Date | null;
  public availability: EDeviceAvailability = EDeviceAvailability.Unavailable;
  public minDateFrom = dayjs(new Date()).add(1, 'day');

  // ENUMS
  protected readonly EDeviceAvailability = EDeviceAvailability;

  ngOnInit() {
    if (typeof this.data.dateFrom === 'string') {
      this.dateFrom = new Date(this.data.dateFrom.split('-'));
      this.dateTo = new Date(this.data.dateTo.split('-'));
      this.availability = this.data.availability ? EDeviceAvailability.Available : EDeviceAvailability.Unavailable;
    } else {
      this.dateFrom = this.data.dateFrom;
    }
  }
}
