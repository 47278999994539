import { NgIf, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CalendarEvent, CalendarModule, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import * as dayjs from 'dayjs';
import { take, tap } from 'rxjs';
import { Months } from '../../constants/calendar.constant';
import { ERoutes } from '../../enums/oe-routes.enum';
import { IChart, IChartSeries } from '../../interfaces/chart.interface';
import { EDeviceAvailability } from '../../interfaces/device.interface';
import { EToolbarHeader } from '../../interfaces/state.interface';
import { EToastMessage, EToastType } from '../../interfaces/toast.interface';
import { DeviceApiService } from '../../services/device-api.service';
import { MaterialService } from '../../services/material.service';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';
import { OeCalendarEventDialogComponent } from '../oe-calendar-event-dialog/oe-calendar-event-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeTableComponent } from '../oe-table/oe-table.component';

registerLocaleData(localeCs);

@Component({
  standalone: true,
  selector: 'oe-device-detail',
  providers: [HttpClient],
  templateUrl: './oe-device-detail.component.html',
  styleUrls: ['./oe-device-detail.component.scss'],
  imports: [
    MatCardModule,
    NgIf,
    MatIconModule,
    NgxChartsModule,
    MatSelectModule,
    MatButtonModule,
    CalendarModule,
    MatDialogModule,
    MatSortModule,
    MatTableModule,
    OeTableComponent,
  ],
})
export class OeDeviceDetailComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;

  // INJECTS
  public _state = inject(StateService);
  public _device = inject(DeviceApiService);
  private _toaster = inject(ToasterService);
  public _router = inject(Router);
  public _material = inject(MaterialService);
  public _toast = inject(ToasterService);
  public _dialog = inject(MatDialog);

  // VARIABLES
  private dataListen: number | undefined;
  private deviceId!: string;
  public chartSource: IChart[] = [{ name: '', series: [] }];
  public tempChartSeries: IChartSeries[] = [];
  public tempChartSeries2: IChartSeries[] = [];
  public tempChartSeries3: IChartSeries[] = [];
  public tempChartSeries4: IChartSeries[] = [];
  public tempChartSeries5: IChartSeries[] = [];
  public eventTableSource!: any;
  public logTableSource!: any;

  public isDeviceOn: boolean = false;
  public viewDate: Date = new Date();
  public events: CalendarEvent[] = [];
  public month!: string;
  public currentMonth = Months[new Date().getMonth()];
  public currentYear = new Date().getFullYear();
  public year!: number;
  public chartDisplayCount: number = 20;

  // ENUMS && CONSTANTS
  public EInterval = EInterval;
  public ERecordsCount = ERecordsCount;
  protected readonly dayjs = dayjs;

  constructor() {
    this._state.header = EToolbarHeader.Device;
  }

  ngOnInit() {
    const routes = this._router.url.split('/');
    this.deviceId = routes[routes.indexOf(ERoutes.Device) + 1];

    this.getChartData();
    this.asyncLoadActualData();
    this.getDeviceAvailability();
    this.getDeviceEvents();
  }

  private getDeviceEvents() {
    this._device
      .getDeviceEvents(this.deviceId)
      .pipe(take(1))
      .subscribe(response => {
        this.logTableSource = response.list.map(event => {
          return {
            id: event.detail.split(' ')[0],
            dateTime: dayjs(event.dateTime).format('YYYY.MM.DD - HH:mm:ss'),
            authorClickable: event.user?.username,
            authorId: event.user?.userId,
            action: event.detail.split(' ')[1] === 'true' ? 'Zapnuto' : 'Vypnuto',
          };
        });
      });
  }

  private getDeviceAvailability() {
    this.eventTableSource = null;
    this._device
      .getDeviceAvailability(this.deviceId, dayjs().startOf('year').toDate(), dayjs().endOf('year').toDate())
      .pipe(take(1))
      .subscribe(response => {
        this.eventTableSource = response.records.map(record => {
          const dayIndex = new Date().getHours() > 6 ? 0 : 1;

          return {
            status: record.type === EDeviceAvailability.Available ? 1 : 0,
            availability: record.type === 'available' ? 'Dostupné' : 'Nedostupné',
            id: record.id,
            from: dayjs(new Date(record.from)).format('YYYY.MM.DD - HH:mm:ss'),
            to: dayjs(new Date(record.to)).format('YYYY.MM.DD - HH:mm:ss'),
            edit: dayjs(record.from).diff(new Date(), 'day') >= dayIndex,
            remove: dayjs(record.from).diff(new Date(), 'day') >= dayIndex,
          };
        });
      });
  }

  ngOnDestroy() {
    clearInterval(this.dataListen);
  }

  public deleteEvent(deviceId: string, from: string) {
    if (dayjs(new Date(from.split('-')[0])).diff(new Date(), 'day') < 1) {
      return;
    } else {
      this._dialog
        .open(OeDeleteConfirmDialogComponent, {
          height: '200px',
          width: '400px',
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result !== undefined) {
            this._device
              .removeDeviceAvailability(deviceId)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.getDeviceAvailability();
                  this._toaster.open(EToastType.Success, EToastMessage.Availability_Remove_Success);
                },
                error: err => {
                  this._toaster.open(EToastType.Danger, err.error.message || EToastMessage.Availability_Remove_Failure);
                },
              });
          }
        });
    }
  }

  public openEventDialog(from: Date | string, to?: Date, availability?: EDeviceAvailability) {
    let dateFrom;
    if (typeof from === 'string') {
      dateFrom = new Date(from.split('-')[0]);
    } else {
      dateFrom = from;
    }

    const event = this.eventTableSource?.find(
      (e: any) =>
        (new Date(e.from.split('-')[0]) <= new Date(from) && new Date(e.to.split('-')[0]) >= new Date(from)) ||
        (e.from === from && e.to === to)
    );

    const dayIndex = new Date().getHours() > 6 ? 0 : 1;

    if (
      dayjs(dateFrom).diff(new Date(), 'day') < dayIndex ||
      dayjs(new Date(event?.from.split('-')[0])).diff(new Date(), 'day') < dayIndex
    ) {
      return;
    } else {
      let dialogBody;
      let type!: 'edit' | 'add';

      if (event) {
        type = 'edit';
        dialogBody = { dateFrom: event.from, dateTo: event.to, availability: event.status };
      } else {
        type = 'add';
        dialogBody = { dateFrom: from, dateTo: to, availability: availability };
      }

      this._dialog
        .open(OeCalendarEventDialogComponent, {
          data: dialogBody,
          height: '400px',
          width: '400px',
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result !== undefined) {
            if (type === 'add') {
              this._device
                .addDeviceAvailability(this.deviceId, result.from, result.to, result.type)
                .pipe(take(1))
                .subscribe({
                  next: () => {
                    this.getDeviceAvailability();
                    this._toaster.open(EToastType.Success, EToastMessage.Availability_Add_Success);
                  },
                  error: err => {
                    this._toaster.open(EToastType.Danger, err.error.message || EToastMessage.Availability_Add_Failure);
                  },
                });
            } else if (type === 'edit') {
              this._device
                .editDeviceAvailability(event?.id as string, result.from, result.to, result.type)
                .pipe(take(1))
                .subscribe({
                  next: () => {
                    this.getDeviceAvailability();
                    this._toaster.open(EToastType.Success, EToastMessage.Availability_Edit_Success);
                  },
                  error: err => {
                    this._toaster.open(EToastType.Danger, err.error.message || EToastMessage.Availability_Edit_Failure);
                  },
                });
            }
          }
        });
    }
  }

  public toggleDevice(active: boolean) {
    this.isDeviceOn = active;
    this._device
      .activatePower(this.deviceId, active)
      .pipe(
        take(1),
        tap({
          next: () => {
            if (active) {
              this._toast.open(EToastType.Success, 'Zařízení je zapnuto');
            } else {
              this._toast.open(EToastType.Success, 'Zařízení je vypnuto');
            }
          },
          error: err => {
            if (active) {
              this._toast.open(EToastType.Danger, err.error.message || 'Zařízení se nepodařilo zapnout');
            } else {
              this._toast.open(EToastType.Danger, err.error.message || 'Zařízení se nepodařilo vypnout');
            }
          },
        })
      )
      .subscribe(response => {
        console.log(response);
      });
  }

  public setInterval(e: EInterval) {
    clearInterval(this.dataListen);
    this.clearChartData();

    let timeout!: number;
    if (e === EInterval.One_Second) {
      timeout = 1000;
    } else if (e === EInterval.Five_Seconds) {
      timeout = 5000;
    } else if (e === EInterval.Fifteen_Seconds) {
      timeout = 15000;
    } else if (e === EInterval.Thirty_Seconds) {
      timeout = 30000;
    } else if (e === EInterval.One_Minute) {
      timeout = 60000;
    } else if (e === EInterval.Five_Minutes) {
      timeout = 300000;
    }
    this.getChartData();
    this.dataListen = setInterval(this.getChartData, timeout);
  }

  public setCount(e: string) {
    this.chartDisplayCount = Number(e);
    this.chartSource = [
      { name: 'pskut', series: this.tempChartSeries.slice(-Number(e)) },
      { name: 'pdgtrend', series: this.tempChartSeries2.slice(-Number(e)) },
      { name: 'mfrr5Plus', series: this.tempChartSeries3.slice(-Number(e)) },
      { name: 'mfrr5SKUT', series: this.tempChartSeries4.slice(-Number(e)) },
      { name: 'pvs', series: this.tempChartSeries5.slice(-Number(e)) },
    ];
  }

  public asyncLoadActualData() {
    this.dataListen = setInterval(this.getChartData, 1000);
  }

  public beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    this._device
      .getDeviceAvailability(this.deviceId, renderEvent.period.start, renderEvent.period.end)
      .pipe(take(1))
      .subscribe(response => {
        this.month = Months[renderEvent.header[3].date.getMonth()];
        this.year = renderEvent.header[3].date.getFullYear();
        renderEvent.body.forEach(day => {
          response.records.forEach(record => {
            if (
              new Date(record.from) <= dayjs(day.date).subtract(-1, 'day').toDate() &&
              new Date(record.to) > day.date
            ) {
              if (!day.isToday) {
                if (record.type === EDeviceAvailability.Available) {
                  day.cssClass = 'success-calendar';
                } else {
                  day.cssClass = 'error-calendar';
                }
              }
            }
          });
        });
      });
  }

  private clearChartData() {
    this.chartSource = [
      { name: 'pskut', series: [] },
      { name: 'pdgtrend', series: [] },
      { name: 'mfrr5Plus', series: [] },
      { name: 'mfrr5SKUT', series: [] },
      { name: 'pvs', series: [] },
    ];
    this.tempChartSeries = [];
    this.tempChartSeries2 = [];
    this.tempChartSeries3 = [];
    this.tempChartSeries4 = [];
    this.tempChartSeries5 = [];
  }

  public getChartData = () => {
    const routes = this._router.url.split('/');
    const deviceId = routes[routes.indexOf(ERoutes.Device) + 1];
    const time = dayjs(new Date()).format('HH:mm:ss');
    this._device
      .getDeviceDetail(deviceId)
      .pipe(take(1))
      .subscribe(deviceDetail => {
        this.tempChartSeries.push({ name: time, value: deviceDetail.actualFullData.pskut });
        this.tempChartSeries2.push({ name: time, value: deviceDetail.actualFullData.pdgtrend });
        this.tempChartSeries3.push({ name: time, value: deviceDetail.actualFullData.mfrr5Plus });
        this.tempChartSeries4.push({ name: time, value: deviceDetail.actualFullData.mfrr5SKUT });
        this.tempChartSeries5.push({ name: time, value: deviceDetail.actualFullData.pvs });
        this.chartSource = [
          { name: 'pskut', series: this.tempChartSeries.slice(-this.chartDisplayCount) },
          { name: 'pdgtrend', series: this.tempChartSeries2.slice(-this.chartDisplayCount) },
          { name: 'mfrr5Plus', series: this.tempChartSeries3.slice(-this.chartDisplayCount) },
          { name: 'mfrr5SKUT', series: this.tempChartSeries4.slice(-this.chartDisplayCount) },
          { name: 'pvs', series: this.tempChartSeries5.slice(-this.chartDisplayCount) },
        ];
        this._state.device = deviceDetail;
      });
  };
}

enum EInterval {
  One_Second = '1s',
  Five_Seconds = '5s',
  Fifteen_Seconds = '15s',
  Thirty_Seconds = '30s',
  One_Minute = '1m',
  Five_Minutes = '5m',
}

enum ERecordsCount {
  FIVE = '5',
  TEN = '10',
  TWENTY = '20',
  FIFTY = '50',
  HUNDRED = '100',
}
