import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { STANDARD_OE_API_URL } from '../constants/standard-oe-api.constant';
import { ICompany } from '../interfaces/company.interface';
import { IListResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService {
  // INJECTS
  private _http = inject(HttpClient);

  public getCompanyList() {
    return this._http.post<IListResponse<ICompany>>(`${STANDARD_OE_API_URL}/company/get-companies`, {});
  }

  public uploadCompany(body: ICompany) {
    return this._http.post<ICompany>(`${STANDARD_OE_API_URL}/company/add-company`, body);
  }

  public editCompany(body: ICompany) {
    return this._http.post<ICompany>(`${STANDARD_OE_API_URL}/company/edit-company`, body);
  }

  public getCompany(companyId: string) {
    return this._http.post<ICompany>(`${STANDARD_OE_API_URL}/company/get-company`, {
      companyId: companyId,
    });
  }

  public deleteCompany(companyId: string) {
    return this._http.post<ICompany>(`${STANDARD_OE_API_URL}/company/delete-company`, {
      companyId: companyId,
    });
  }
}
