import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterOutlet } from '@angular/router';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { OeToolbarComponent } from './shared/components/oe-toolbar/oe-toolbar.component';
import { ERoutes } from './shared/enums/oe-routes.enum';
import { AuthApiService } from './shared/services/auth-api.service';
import { StateService } from './shared/services/state.service';

@Component({
  standalone: true,
  selector: 'app-root',
  providers: [HttpClient],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [OeToolbarComponent, RouterOutlet, MatSidenavModule, MatButtonModule, MatIconModule, NgIf],
})
export class AppComponent {
  // INJECTS
  public _auth = inject(AuthApiService);
  public _state = inject(StateService);
  public _router = inject(Router);

  // ENUMS
  protected readonly ERoutes = ERoutes;

  constructor(dateTimeAdapter: DateTimeAdapter<any>) {
    dateTimeAdapter.setLocale('cz-CZ'); // change locale to Japanese
  }
}
