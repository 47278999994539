<div style='margin: 30px 15px; min-width: max-content'>
  <div class='table-toolbar'>
    <button mat-stroked-button color="primary" (click)='openCompanyHandleDialog()'>
      Přidat firmu
      <mat-icon class='oe-primary-icon' fontIcon="add" style='margin-right: 6px'></mat-icon>
    </button>
  </div>
  <oe-table
    *ngIf='tableSource'
    [data]='tableSource'
    [pageSize]='10'
    [hiddenColumns]="['companyId']"
    (rowEdit)='openCompanyHandleDialog($event)'
    (rowDelete)='openDeleteDialog($event)'
    (detailOpened)='_router.navigate([ERoutes.Main, ERoutes.Company_Management, $event.companyId])'>
  </oe-table>
</div>
