import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { STANDARD_OE_API_URL } from '../constants/standard-oe-api.constant';
import { IConsumptionPoint } from '../interfaces/consumption-point.interface';
import { ICatalogue, IListResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class ConsumptionPointApiService {
  // INJECTS
  private _http = inject(HttpClient);

  public getConsumptionPoint(consumptionPointId: string) {
    return this._http.post<IConsumptionPoint>(`${STANDARD_OE_API_URL}/consumption-point/get-consumption-point`, {
      consumptionPointId: consumptionPointId,
    });
  }

  public uploadConsumptionPoint(body: IConsumptionPoint) {
    return this._http.post<IConsumptionPoint>(
      `${STANDARD_OE_API_URL}/consumption-point/create-consumption-point`,
      body
    );
  }

  public editConsumptionPoint(body: IConsumptionPoint) {
    return this._http.post<IConsumptionPoint>(`${STANDARD_OE_API_URL}/consumption-point/edit-consumption-point`, body);
  }

  public deleteConsumptionPoint(consumptionPointId: string) {
    return this._http.post(`${STANDARD_OE_API_URL}/consumption-point/delete-consumption-point`, {
      consumptionPointId: consumptionPointId,
    });
  }

  public getConsumptionPoints(companyId: string) {
    return this._http.post<IListResponse<IConsumptionPoint>>(
      `${STANDARD_OE_API_URL}/consumption-point/get-consumption-points`,
      { companyId: companyId }
    );
  }

  public getElectricityDistributors() {
    return this._http.post<IListResponse<ICatalogue>>(
      `${STANDARD_OE_API_URL}/consumption-point/get-electricity-distributors`,
      {}
    );
  }

  public getGasDistributors() {
    return this._http.post<IListResponse<ICatalogue>>(
      `${STANDARD_OE_API_URL}/consumption-point/get-gas-distributors`,
      {}
    );
  }
}
