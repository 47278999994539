import { JsonPipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { forkJoin, Observable, switchMap, take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { IDevice, IDeviceAvailability, IDeviceEvent } from '../../interfaces/device.interface';
import { IListResponse, IRecordsResponse } from '../../interfaces/response.interface';
import { EToolbarHeader } from '../../interfaces/state.interface';
import { EToastMessage, EToastType } from '../../interfaces/toast.interface';
import { DeviceApiService } from '../../services/device-api.service';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';
import { OeCalendarEventDialogComponent } from '../oe-calendar-event-dialog/oe-calendar-event-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeTableComponent } from '../oe-table/oe-table.component';

@Component({
  standalone: true,
  selector: 'oe-svr-overview',
  providers: [HttpClient],
  templateUrl: './oe-svr-overview.component.html',
  styleUrls: ['./oe-svr-overview.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    NgIf,
    MatDialogModule,
    JsonPipe,
    MatPaginatorModule,
    OeTableComponent,
    MatCardModule,
  ],
})
export class OeSvrOverviewComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // INJECTS
  public _state = inject(StateService);
  public _device = inject(DeviceApiService);
  public _router = inject(Router);
  public _dialog = inject(MatDialog);
  private _toaster = inject(ToasterService);

  // VARIABLES
  public devicesTableSource!: any;
  public logsTableSource!: any;
  public calendarTableSource!: any;
  public deviceList!: IDevice[];
  public totalActualPower!: number;
  public totalAvailablePower!: number;
  public logList: (IDeviceEvent | any)[] = [];
  public calendarList: any[] = [];
  public showBody = false;

  // ENUMS && CONSTANTS
  protected readonly ERoutes = ERoutes;
  protected readonly dayjs = dayjs;

  constructor() {
    this._state.header = EToolbarHeader.SVR;
  }

  ngOnInit() {
    this.dataSetup();
  }

  private dataSetup() {
    this.showBody = false;
    this.calendarList = [];
    this.logList = [];
    this.deviceList = [];
    this._device
      .getOwnerDevices()
      .pipe(
        take(1),
        switchMap(devices => {
          this.deviceList = devices.list;
          this.totalActualPower = (devices as any).totalActualPowerMW;
          this.totalAvailablePower = (devices as any).totalAvailablePowerMW;

          this.devicesTableSource = devices.list.map(device => {
            return {
              index: true,
              deviceId: device.deviceId,
              deviceName: device.name,
              actualPower: (device.actualPower?.toFixed(2) || '0') + ' kW',
              detail: true,
            };
          });

          const eventsRequests: Observable<IListResponse<IDeviceEvent>>[] = [];
          const calendarRequests: Observable<IRecordsResponse<IDeviceAvailability>>[] = [];
          devices.list.forEach(device => {
            eventsRequests.push(this._device.getDeviceEvents(device.deviceId));
            calendarRequests.push(
              this._device.getDeviceAvailability(
                device.deviceId,
                dayjs().startOf('year').toDate(),
                dayjs().endOf('year').toDate()
              )
            );
          });
          return forkJoin({ events: forkJoin(eventsRequests), calendar: forkJoin(calendarRequests) });
        })
      )
      .subscribe(response => {
        void response.events.forEach(res => {
          this.logList = this.logList.concat(res.list);
        });

        void response.calendar.forEach(res => {
          this.calendarList = this.calendarList.concat(res.records);
        });

        this.calendarTableSource = this.calendarList
          .map(calendar => {
            const dayIndex = new Date().getHours() > 6 ? 0 : 1;

            return {
              deviceId: calendar.deviceId,
              status: calendar.type === 'available',
              availability: calendar.type === 'available' ? 'Dostupné' : 'Nedostupné',
              deviceNameClickable: this.deviceList.find(device => device.deviceId === calendar.deviceId)?.name,
              from: dayjs(calendar.from).format('YYYY.MM.DD - HH:mm:ss'),
              to: dayjs(calendar.to).format('YYYY.MM.DD - HH:mm:ss'),
              edit: dayjs(calendar.from).diff(new Date(), 'day') >= dayIndex,
              remove: dayjs(calendar.from).diff(new Date(), 'day') >= dayIndex,
            };
          })
          .sort((a: any, b: any) => {
            return dayjs(new Date(b.from.split('-')[0])).isAfter(a.from.split('-')[0]) ? -1 : 1;
          });

        this.logList = this.logList.map(event => {
          return {
            id: event.detail.split(' ')[0],
            deviceId: this.deviceList.find(device => device.deviceId === event.detail.split(' ')[0])?.deviceId,
            deviceNameClickable: this.deviceList.find(device => device.deviceId === event.detail.split(' ')[0])?.name,
            dateTime: dayjs(event.dateTime).format('YYYY.MM.DD - HH:mm:ss'),
            authorClickable: event.user?.username,
            authorId: event.user?.userId,
            action: event.detail.split(' ')[1] === 'true' ? 'Zapnuto' : 'Vypnuto',
          };
        });

        this.logsTableSource = this.logList.sort((a, b) => {
          return b.dateTime - a.dateTime;
        });

        this.showBody = true;
      });
  }

  public deleteEvent(deviceId: string, from: string) {
    const dateFrom = new Date(from.split('-')[0]);

    if (dayjs(dateFrom).diff(new Date(), 'day') < 1) {
      return;
    } else {
      this._dialog
        .open(OeDeleteConfirmDialogComponent, {
          height: '200px',
          width: '400px',
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result !== undefined) {
            this._device
              .removeDeviceAvailability(deviceId)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.dataSetup();
                  this._toaster.open(EToastType.Success, EToastMessage.Availability_Remove_Success);
                },
                error: err => {
                  this._toaster.open(EToastType.Danger, err.error.message || EToastMessage.Availability_Remove_Failure);
                },
              });
          }
        });
    }
  }

  public openEventDialog(from: string, to: string) {
    const dateFrom = new Date(from.split('-')[0]);

    if (dayjs(dateFrom).diff(new Date(), 'day') < 1) {
      return;
    } else {
      const event = this.calendarTableSource?.find((e: any) => e.from === from && e.to === to);

      this._dialog
        .open(OeCalendarEventDialogComponent, {
          data: { dateFrom: event?.from, dateTo: event?.to, availability: event?.status },
          height: '400px',
          width: '400px',
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result !== undefined) {
            this._device
              .editDeviceAvailability(event?.id as string, result.from, result.to, result.type)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.dataSetup();
                  this._toaster.open(EToastType.Success, EToastMessage.Availability_Edit_Success);
                },
                error: err => {
                  this._toaster.open(EToastType.Danger, err.error.message || EToastMessage.Availability_Edit_Failure);
                },
              });
          }
        });
    }
  }

  public openLogDetail(e: any) {
    console.log(e);
  }
}
