import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { EToolbarHeader } from '../../interfaces/state.interface';
import { EToastType } from '../../interfaces/toast.interface';
import { AuthApiService } from '../../services/auth-api.service';
import { StateService } from '../../services/state.service';

@Component({
  standalone: true,
  selector: 'oe-toolbar',
  providers: [HttpClient],
  templateUrl: './oe-toolbar.component.html',
  styleUrls: ['./oe-toolbar.component.scss'],
  imports: [MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule, NgIf, MatBadgeModule],
})
export class OeToolbarComponent implements OnInit {
  // INJECTS
  public _state = inject(StateService);
  public _router = inject(Router);
  public _auth = inject(AuthApiService);

  // VARIABLES
  public version!: string;

  // ENUMS
  protected readonly ERoutes = ERoutes;
  protected readonly EToolbarHeader = EToolbarHeader;

  ngOnInit() {
    this._auth
      .getAppVersion()
      .pipe(
        take(1),
        tap({
          next: version => {
            console.log(version);
          },
          error: err => {
            this.version = err.error.text;
          },
        })
      )
      .subscribe();
  }
}
