<div class='view-wrapper' style='margin: 30px 0' *ngIf='_state.user'>
  <div style='margin: 15px'>
    <div class='table-toolbar'>
      <button mat-stroked-button color="primary" style='margin-right: 12px' (click)='openUserHandleDialog()'>
        Upravit uživatele
        <mat-icon class='oe-primary-icon' fontIcon="edit" style='margin-right: 6px'></mat-icon>
      </button>

      <button mat-stroked-button color="primary" style='margin-right: 12px' (click)='openDeleteDialog()'>
        Smazat uživatele
        <mat-icon class='oe-primary-icon' fontIcon="delete" style='margin-right: 6px'></mat-icon>
      </button>

      <button mat-stroked-button color="primary" (click)='openPasswordChangeDialog()'>
        Změnit heslo
        <mat-icon class='oe-primary-icon' fontIcon="lock" style='margin-right: 6px'></mat-icon>
      </button>
    </div>
    <mat-card>
      <div class='m-3'>
        <div class='odd-row'><h5>Jméno</h5>{{_state.user.username}}</div>
        <div class='even-row'><h5>E-mail</h5>{{_state.user.email}}</div>
        <div class='odd-row'><h5>Role</h5>{{_state.user.role}}</div>
      </div>
    </mat-card>
  </div>
</div>
