import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { STANDARD_OE_API_URL } from '../constants/standard-oe-api.constant';
import { IBoxServiceGroup } from '../interfaces/box-service-group.interface';
import { IListResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class BoxServiceGroupApiService {
  // INJECTS
  private _http = inject(HttpClient);

  public getServiceGroups(companyId?: string) {
    const body: any = {};
    if (companyId) {
      body.companyId = companyId;
    }

    return this._http.post<IListResponse<IBoxServiceGroup>>(
      `${STANDARD_OE_API_URL}/box/group-service/get-service-groups`,
      body
    );
  }

  public createServiceGroup(name: string, companyId: string) {
    return this._http.post<IBoxServiceGroup>(`${STANDARD_OE_API_URL}/box/group-service/create-service-group`, {
      name: name,
      companyId: companyId,
    });
  }

  public editServiceGroup(name: string, groupId: string, serviceIds?: string[]) {
    return this._http.post<IBoxServiceGroup>(`${STANDARD_OE_API_URL}/box/group-service/edit-service-group`, {
      name: name,
      serviceGroupId: groupId,
      serviceIds: serviceIds,
    });
  }

  public deleteServiceGroup(groupId: string) {
    return this._http.post(`${STANDARD_OE_API_URL}/box/group-service/delete-service-group`, {
      serviceGroupId: groupId,
    });
  }
}
