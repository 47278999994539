<div class='view-wrapper' style='margin: 15px 0' *ngIf='_state.device'>
  <div style='margin: 15px'>
    <div style='margin: 15px'>
      <mat-card>
        <div class='m-3'>
          <div class='odd-row'><h5>Aktivní</h5>
            <mat-icon *ngIf="_state.device.config.activationBit !== 1" style='color: #5cb85c' fontIcon="check_circle"></mat-icon>
            <mat-icon *ngIf="_state.device.config.activationBit === 1" style='color: #ed4337' fontIcon="cancel"></mat-icon>
          </div>
          <div class='even-row'><h5>Jméno</h5>{{_state.device.deviceName}}</div>
<!--          <div style='display:flex; align-items: center'>-->
<!--            <h5 class='property-label' style='flex: 1 0 0;'>Vlastník</h5>-->
<!--            <mat-icon class='oe-primary-icon' fontIcon="edit"></mat-icon>-->
<!--          </div>-->
<!--          <div class='odd-row'><h5>Jméno</h5>{{_state.device.company.name}}</div>-->
<!--          <div class='even-row'><h5>Adresa</h5>{{_state.device.company.address}}</div>-->
<!--          <div class='odd-row'><h5>E-mail</h5>{{_state.device.company.email}}</div>-->
<!--          <div class='even-row'><h5>Telefon</h5>{{_state.device.company.phone}}</div>-->
<!--          <div class='odd-row'><h5>IC</h5>{{_state.device.company.ic}}</div>-->
<!--          <div class='even-row'><h5>DIC</h5>{{_state.device.company.dic}}</div>-->
        </div>
      </mat-card>
    </div>

    <div style='margin: 15px'>
      <div style='display:flex; align-items: center'>
        <div class='table-toolbar' style='width: 100%; justify-content: space-between; height: 70px'>
          <h5 class='card-label' style='margin-bottom: 0; align-self: center'>Výkon</h5>

          <div>
            <mat-form-field style='margin-right: 8px'>
              <mat-label>Interval</mat-label>
              <mat-select [value]="'1s'" (valueChange)='setInterval($event)'>
                <mat-option [value]="EInterval.One_Second">{{ EInterval.One_Second }}</mat-option>
                <mat-option [value]="EInterval.Five_Seconds">{{ EInterval.Five_Seconds }}</mat-option>
                <mat-option [value]="EInterval.Fifteen_Seconds">{{ EInterval.Fifteen_Seconds }}</mat-option>
                <mat-option [value]="EInterval.Thirty_Seconds">{{ EInterval.Thirty_Seconds }}</mat-option>
                <mat-option [value]="EInterval.One_Minute">{{ EInterval.One_Minute }}</mat-option>
                <mat-option [value]="EInterval.Five_Minutes">{{ EInterval.Five_Minutes }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Počet záznamů</mat-label>
              <mat-select [value]="'20'" (valueChange)='setCount($event)'>
                <mat-option [value]="ERecordsCount.FIVE">{{ ERecordsCount.FIVE }}</mat-option>
                <mat-option [value]="ERecordsCount.TEN">{{ ERecordsCount.TEN }}</mat-option>
                <mat-option [value]="ERecordsCount.TWENTY">{{ ERecordsCount.TWENTY }}</mat-option>
                <mat-option [value]="ERecordsCount.FIFTY">{{ ERecordsCount.FIFTY }}</mat-option>
                <mat-option [value]="ERecordsCount.HUNDRED">{{ ERecordsCount.HUNDRED }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-card style='height: 445px'>
        <ngx-charts-line-chart
          [xAxis]="true"
          [yAxis]="true"
          [yAxisTickFormatting]='_material.formatPower'
          [scheme]="_state.chartColorScheme"
          [results]="chartSource"
          [gradient]="true">
        </ngx-charts-line-chart>
      </mat-card>
    </div>

    <div style='display: flex; justify-content: center'>
      <button
        mat-raised-button
        [disabled]='isDeviceOn'
        color="primary"
        style='width: 100px'
        (click)='toggleDevice(true)'>
        Spustit
      </button>

      <button
        mat-raised-button
        [disabled]='!isDeviceOn'
        color="primary"
        style='width: 100px; margin-left: 35px'
        (click)='toggleDevice(false)'>
        Vypnout
      </button>
    </div>

    <div style='margin: 15px'>
      <div class='table-toolbar'>
        <h5 class='card-label' style='margin-bottom: 0; color: white'>Kalendář</h5>
      </div>
      <mat-card style='padding: 20px'>
        <span style='text-align: center; margin-bottom: 15px; font-size: 22px'>{{ month }} {{ year }}</span>

        <div class="btn-group" style='justify-content: center; margin-bottom: 15px'>
          <button
            mat-raised-button color="primary"
            mwlCalendarPreviousView
            [view]="'month'"
            [(viewDate)]="viewDate">
            Předchozí
          </button>
          <button
            [disabled]='month === currentMonth && currentYear === year'
            style='margin: 0 35px'
            mat-raised-button color="primary"
            mwlCalendarToday
            [(viewDate)]="viewDate">
            Dnes
          </button>
          <button
            mat-raised-button color="primary"
            mwlCalendarNextView
            [view]="'month'"
            [(viewDate)]="viewDate">
            Následující
          </button>
        </div>

        <mwl-calendar-month-view
          *ngIf='eventTableSource'
          [locale]="'cs'"
          (dayClicked)="openEventDialog($event.day.date, undefined, undefined)"
          [viewDate]="viewDate"
          (beforeViewRender)="beforeMonthViewRender($event)"
          [weekStartsOn]='1'>
        </mwl-calendar-month-view>
      </mat-card>
    </div>

    <div style='margin: 30px 15px'>
      <div class='table-toolbar'>
        <h5 class='card-label' style='margin-bottom: 0; color: white'>Dostupnost</h5>
      </div>
      <oe-table
        *ngIf='eventTableSource'
        [data]='eventTableSource'
        [hiddenColumns]="[]"
        (rowDelete)='deleteEvent($event.id, $event.from)'
        (rowEdit)="openEventDialog($event.from, $event.to, $event.status)">
      </oe-table>
    </div>

    <div style='margin: 30px 15px'>
      <div class='table-toolbar'>
        <h5 class='card-label' style='margin-bottom: 0; color: white'>Log eventů</h5>
      </div>
      <oe-table
        *ngIf='logTableSource'
        [data]='logTableSource'
        [hiddenColumns]="['authorId']">
      </oe-table>
    </div>
  </div>
</div>
