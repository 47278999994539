import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { STANDARD_OE_API_URL } from '../constants/standard-oe-api.constant';
import { IHeatingBranchData, IHeatingBranchSettings } from '../interfaces/heating-branch.interface';
import { IListResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class HeatingBranchApiService {
  // INJECTS
  private _http = inject(HttpClient);

  public getBranchDataList(companyId?: string) {
    const body: any = {};
    if (companyId) {
      body.companyId = companyId;
    }

    return this._http.post<IListResponse<IHeatingBranchData>>(
      `${STANDARD_OE_API_URL}/box/service/get-heating-branch-data`,
      body
    );
  }

  public getBranchSettings(serviceId: string) {
    return this._http.post<IHeatingBranchSettings>(`${STANDARD_OE_API_URL}/box/service/get-heating-branch-settings`, {
      serviceId: serviceId,
    });
  }

  public updateBranchSettings(serviceId: string, settings: IHeatingBranchSettings) {
    return this._http.post<IHeatingBranchSettings>(`${STANDARD_OE_API_URL}/box/service/set-heating-branch-settings`, {
      ...settings,
      serviceId: serviceId,
    });
  }
}
