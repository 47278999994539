import { NgForOf, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatOption } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { forkJoin, take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { IBoxDevice, IBoxService } from '../../interfaces/box-service.interface';
import {
  EHeatingBranchCirculationPump,
  EHeatingBranchStatus,
  IHeatingBranchData,
} from '../../interfaces/heating-branch.interface';
import { EToolbarHeader } from '../../interfaces/state.interface';
import { EToastMessage, EToastType } from '../../interfaces/toast.interface';
import { BoxDeviceApiService } from '../../services/box-device-api.service';
import { BoxServiceApiService } from '../../services/box-service-api.service';
import { CompanyApiService } from '../../services/company-api.service';
import { HeatingBranchApiService } from '../../services/heating-branch-api.service';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';
import { OeBoxServiceHandleDialogComponent } from '../oe-box-service-handle-dialog/oe-box-service-handle-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeTableComponent } from '../oe-table/oe-table.component';

@Component({
  standalone: true,
  selector: 'oe-heating-branch-management',
  providers: [HttpClient],
  templateUrl: './oe-heating-branch-management.component.html',
  styleUrls: ['./oe-heating-branch-management.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    NgIf,
    OeTableComponent,
    NgForOf,
    MatSlideToggle,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
  ],
})
export class OeHeatingBranchManagementComponent implements OnInit, OnDestroy {
  // INJECTS
  private _state = inject(StateService);
  private _boxService = inject(BoxServiceApiService);
  private _boxDevice = inject(BoxDeviceApiService);
  private _dialog = inject(MatDialog);
  private _toast = inject(ToasterService);
  private _company = inject(CompanyApiService);
  public _router = inject(Router);
  public _heatingBranch = inject(HeatingBranchApiService);

  // VARIABLES
  private dataListen: number | undefined;
  public tableSource!: any;
  public branches: IHeatingBranchData[] = [];

  // ENUMS
  protected readonly ERoutes = ERoutes;
  protected readonly ECirculationPump = EHeatingBranchCirculationPump;
  protected readonly EHeatingBranchStatus = EHeatingBranchStatus;

  constructor() {
    this._state.header = EToolbarHeader.Heating_Branch_Management;
  }

  ngOnInit() {
    // this.setupTable();
    this.setupBranches();
    this.asyncLoadActualData();
  }

  ngOnDestroy() {
    clearInterval(this.dataListen);
  }

  public getChartData = () => {
    this.setupBranches();
  };

  public asyncLoadActualData() {
    this.dataListen = setInterval(this.getChartData, 5000);
  }

  private setupBranches() {
    this._heatingBranch
      .getBranchDataList()
      .pipe(take(1))
      .subscribe(res => {
        this.branches = res.list;
      });
  }

  private setupTable() {
    this.tableSource = null;

    this._company
      .getCompanyList()
      .pipe(take(1))
      .subscribe(res => {
        const companies = res.list;
        const requests = res.list.map(company => {
          return this._boxDevice.getBoxDeviceList(company.companyId as string);
        });

        forkJoin(requests)
          .pipe(take(1))
          .subscribe(res => {
            const devices: IBoxDevice[] = [];
            res.forEach(r => {
              r.devices.forEach(device => {
                devices.push(device);
              });
            });

            const requests = devices.map(d => {
              return this._boxService.getBoxServiceList(d.deviceId);
            });

            forkJoin(requests)
              .pipe(take(1))
              .subscribe(res => {
                const boxServiceList: IBoxService[] = [];
                res.forEach(r => {
                  r.services?.forEach(box => {
                    boxServiceList.push(box);
                  });
                });

                this.tableSource = boxServiceList.map(service => {
                  return {
                    id: service.serviceId,
                    deviceId: service.deviceId,
                    // deviceNameClickable: devices.find(d => d.deviceId === service.deviceId)?.name,
                    deviceName: devices.find(d => d.deviceId === service.deviceId)?.name,
                    name: service.name,
                    serviceName: service.serviceName,
                    type: service.type,
                    note: service.note,
                    companyNameClickable: companies.find(c => c.companyId === service.companyId)?.name,
                    companyId: service.companyId,
                    detail: true,
                    edit: true,
                    remove: true,
                  };
                });
              });
          });
      });
  }

  public openServiceHandleDialog(box?: IBoxService) {
    this._dialog
      .open(OeBoxServiceHandleDialogComponent, {
        height: '420px',
        width: '780px',
        data: box,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (!box) {
            this._boxService
              .createBoxService(result)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setupTable();
                  this.setupBranches();
                  this._toast.open(EToastType.Success, EToastMessage.BoxService_Create_Success);
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné vytvořit.');
                },
              });
          } else {
            this._boxService
              .editBoxService(result)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setupTable();
                  this._toast.open(EToastType.Success, 'Služba byla úspěšně upravena.');
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné upravit.');
                },
              });
          }
        }
      });
  }

  public openDeleteDialog(box: IBoxService | any) {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._boxService
            .deleteBoxService(box.id as string)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.setupTable();
                this._toast.open(EToastType.Success, 'Služba byla úspěšně smazána.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné smazat.');
              },
            });
        }
      });
  }
}
