<div style='position: relative'>
  <mat-icon class='oe-close-icon' style='position: absolute; top: 10px; right: 10px' (click)='dialogRef.close()' fontIcon="close"></mat-icon>

  <div style='position: relative; top: 50px'>
    <h3 style='text-align: center; margin-bottom: 25px'>{{ this.data ? 'Upravit uživatele' : 'Přidat uživatele' }}</h3>

    <div style='display: flex; flex-wrap: wrap; margin: 0 30px; justify-content: space-between'>
      <mat-form-field style='width: 350px'>
        <mat-label>Jméno</mat-label>
        <input [(ngModel)]='user.userName' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>E-mail</mat-label>
        <input [(ngModel)]='user.email' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 350px' *ngIf='!data'>
        <mat-label>Heslo</mat-label>
        <input [(ngModel)]='user.password' [type]="'password'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>Role</mat-label>
        <mat-select [(value)]="user.role">
          <mat-option [value]="EUserRole.Admin_Partner">{{ 'Admin partner' }}</mat-option>
          <mat-option [value]="EUserRole.Admin_Oe">{{ 'Admin OE' }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>Firma</mat-label>
        <mat-select [(value)]="user.companyId">
          <mat-option [value]="company.companyId" *ngFor='let company of companyList'>{{ company.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style='display: flex; justify-content: center; margin-top: 15px'>
      <button
        (click)='dialogRef.close()'
        style='margin-right: 10px'
        mat-button color="primary">
        Zrušit
      </button>
      <button
        (click)='dialogRef.close(user)'
        style='margin-left: 10px'
        mat-raised-button color="primary">
        {{ this.data ? 'Upravit' : 'Přidat' }}
      </button>
    </div>
  </div>
</div>
