<div class='view-wrapper' style='margin: 30px 0' *ngIf='consumptionPoint'>
  <div style='margin: 15px'>
    <div class='table-toolbar'>
      <button mat-stroked-button color="primary" style='margin-right: 12px' (click)='openConsumptionPointHandleDialog(consumptionPoint)'>
        Upravit odběrné místo
        <mat-icon class='oe-primary-icon' fontIcon="edit" style='margin-right: 6px'></mat-icon>
      </button>

      <button mat-stroked-button color="primary" (click)='openDeleteConfirmDialog(consumptionPoint)'>
        Smazat odběrné místo
        <mat-icon class='oe-primary-icon' fontIcon="delete" style='margin-right: 6px'></mat-icon>
      </button>
    </div>
    <mat-card>
      <div class='m-3'>
        <div class='odd-row'><h5>EAN</h5>{{consumptionPoint.ean}}</div>
        <div class='even-row'><h5>EIC</h5>{{consumptionPoint.eic}}</div>
        <div class='odd-row'><h5>Adresa</h5>{{consumptionPoint.address}}</div>
        <div class='even-row'><h5>Distributor elektřiny</h5>{{consumptionPoint.electricityDistributor}}</div>
        <div class='odd-row'><h5>Distributor plynu</h5>{{consumptionPoint.gasDistributor}}</div>
        <div class='even-row'><h5>GPS</h5>{{consumptionPoint.gps}}</div>
      </div>
    </mat-card>
  </div>
</div>
