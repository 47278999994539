<div class='view-wrapper' style='margin: 30px 0' *ngIf='_state.company'>
  <div style='margin: 15px'>
    <div class='table-toolbar'>
      <button mat-stroked-button color="primary" style='margin-right: 12px' (click)='openCompanyHandleDialog()'>
        Upravit firmu
        <mat-icon class='oe-primary-icon' fontIcon="edit" style='margin-right: 6px'></mat-icon>
      </button>

      <button mat-stroked-button color="primary" (click)='openDeleteCompanyDialog()'>
        Smazat firmu
        <mat-icon class='oe-primary-icon' fontIcon="delete" style='margin-right: 6px'></mat-icon>
      </button>
    </div>
    <mat-card>
      <div class='m-3'>
        <div class='odd-row'><h5>Název</h5>{{_state.company.name}}</div>
        <div class='even-row'><h5>DIČ</h5>{{_state.company.dic}}</div>
        <div class='odd-row'><h5>IČ</h5>{{_state.company.ic}}</div>
        <div class='even-row'><h5>Sídlo</h5>{{_state.company.address}}</div>
        <div class='odd-row'><h5>E-mail</h5>{{_state.company.email}}</div>
        <div class='even-row'><h5>Telefon</h5>{{_state.company.phone}}</div>
      </div>
    </mat-card>
  </div>

  <div style='margin: 30px 15px; min-width: max-content'>
    <div class='table-toolbar'>
      <button mat-stroked-button color="primary" (click)='openBoxDeviceHandleDialog()'>
        Přidat SPOTBOX
        <mat-icon class='oe-primary-icon' fontIcon="add" style='margin-right: 6px'></mat-icon>
      </button>
    </div>
    <oe-table
      *ngIf='tableSource'
      [data]='tableSource'
      (rowEdit)='openBoxDeviceHandleDialog($event)'
      (rowDelete)='openDeleteBoxDeviceDialog($event)'
      (detailOpened)='_router.navigate([ERoutes.Main, ERoutes.Company_Management, _state.company.companyId, ERoutes.Spotbox_Management, $event.id])'
      [pageSize]='10'>
    </oe-table>
  </div>
</div>
