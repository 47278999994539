import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { forkJoin, switchMap, take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { IConsumptionPoint } from '../../interfaces/consumption-point.interface';
import { ICatalogue } from '../../interfaces/response.interface';
import { EToastType } from '../../interfaces/toast.interface';
import { CompanyApiService } from '../../services/company-api.service';
import { ConsumptionPointApiService } from '../../services/consumption-point-api.service';
import { ToasterService } from '../../services/toaster.service';
import { OeConsumptionPointHandleDialogComponent } from '../oe-consumption-point-handle-dialog/oe-consumption-point-handle-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeTableComponent } from '../oe-table/oe-table.component';

@Component({
  standalone: true,
  selector: 'oe-consumption-point-management',
  providers: [HttpClient],
  templateUrl: './oe-consumption-point-management.component.html',
  styleUrls: ['./oe-consumption-point-management.component.scss'],
  imports: [MatButton, NgIf, OeTableComponent, MatIcon],
})
export class OeConsumptionPointManagementComponent implements OnInit {
  // INJECTS
  private _dialog = inject(MatDialog);
  private _consumptionPoint = inject(ConsumptionPointApiService);
  private _company = inject(CompanyApiService);
  private _toast = inject(ToasterService);
  public _router = inject(Router);

  // VARIABLES
  public consumptionPointTable: any;
  public elDistributors!: ICatalogue[];
  public gasDistributors!: ICatalogue[];

  // ENUMS
  protected readonly ERoutes = ERoutes;

  ngOnInit() {
    this.setupTable();

    this._consumptionPoint
      .getElectricityDistributors()
      .pipe(take(1))
      .subscribe(distributors => {
        this.elDistributors = distributors.list;
      });

    this._consumptionPoint
      .getGasDistributors()
      .pipe(take(1))
      .subscribe(distributors => {
        this.gasDistributors = distributors.list;
      });
  }

  public openConsumptionPointHandleDialog(consumptionPoint?: IConsumptionPoint) {
    this._dialog
      .open(OeConsumptionPointHandleDialogComponent, {
        height: '500px',
        width: '780px',
        data: consumptionPoint,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (!consumptionPoint) {
            this._consumptionPoint
              .uploadConsumptionPoint(result)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setupTable();
                  this._toast.open(EToastType.Success, 'Odběrné místo bylo úspěšně vytvořeno.');
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Odběrné místo nebylo možné vytvořit.');
                },
              });
          } else {
            this._consumptionPoint
              .editConsumptionPoint({ ...result, consumptionPointId: consumptionPoint.consumptionPointId })
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setupTable();
                  this._toast.open(EToastType.Success, 'Odběrné místo bylo úspěšně upraveno.');
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Odběrné místo nebylo možné upravit.');
                },
              });
          }
        }
      });
  }

  public openDeleteConfirmDialog(consumptionPoint: IConsumptionPoint) {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result !== undefined) {
          this._consumptionPoint
            .deleteConsumptionPoint(consumptionPoint.consumptionPointId as string)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.setupTable();
                this._toast.open(EToastType.Success, 'Odběrné místo bylo úspěšně smazáno.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Odběrné místo nebylo možné smazat.');
              },
            });
        }
      });
  }

  private setupTable() {
    this.consumptionPointTable = null;

    this._company
      .getCompanyList()
      .pipe(
        take(1),
        switchMap(companies => {
          const requests = companies.list.map(company => {
            return this._consumptionPoint.getConsumptionPoints(company.companyId as string);
          });
          return forkJoin(requests);
        })
      )
      .subscribe(response => {
        let consumptionPoints: IConsumptionPoint[] = [];
        response.forEach(point => {
          consumptionPoints = consumptionPoints.concat(point?.list);
        });

        this.consumptionPointTable = consumptionPoints?.map(point => {
          return {
            locationIcon: true,
            consumptionPointId: point.consumptionPointId,
            ean: point.ean,
            eic: point.eic,
            address: point.address,
            electricityDistributor: this.elDistributors.find(d => d.key === point.electricityDistributor)?.value,
            gasDistributor: this.gasDistributors.find(d => d.key === point.gasDistributor)?.value,
            gps: point.gps,
            detail: true,
            edit: true,
            remove: true,
          };
        });
      });
  }
}
