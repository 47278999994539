import { Routes } from '@angular/router';
import { OeHeatingBranchDetailComponent } from '../components/heating-branch-detail/oe-heating-branch-detail.component';
import { OeCompanyDetailComponent } from '../components/oe-company-detail/oe-company-detail.component';
import { OeCompanyManagementComponent } from '../components/oe-company-management/oe-company-management.component';
import { OeConsumptionPointDetailComponent } from '../components/oe-consumption-point-detail/oe-consumption-point-detail.component';
import { OeDashboardComponent } from '../components/oe-dashboard/oe-dashboard.component';
import { OeDeviceDetailComponent } from '../components/oe-device-detail/oe-device-detail.component';
import { OeConsumptionPointManagementComponent } from '../components/oe-consumption-point-management/oe-consumption-point-management.component';
import { OeGroupManagementComponent } from '../components/oe-group-management/oe-group-management.component';
import { OeHeatingBranchManagementComponent } from '../components/oe-heating-branch-management/oe-heating-branch-management.component';
import { OeLoginComponent } from '../components/oe-login/oe-login.component';
import { OeSpotBoxDetailComponent } from '../components/oe-spot-box-detail/oe-spot-box-detail.component';
import { OeSvrOverviewComponent } from '../components/oe-svr-overview/oe-svr-overview.component';
import { OeUserDetailComponent } from '../components/oe-user-detail/oe-user-detail.component';
import { OeUserManagementComponent } from '../components/oe-user-management/oe-user-management.component';
import { OeServiceGroupDetailComponent } from '../components/service-group-detail/oe-service-group-detail.component';
import { ERoutes } from '../enums/oe-routes.enum';

export const appRoutes: Routes = [
  {
    path: ERoutes.Main,
    children: [
      {
        path: ERoutes.Dashboard,
        component: OeDashboardComponent,
      },
      {
        path: ERoutes.SVR,
        component: OeSvrOverviewComponent,
      },
      // {
      //   path: ERoutes.Aggregators,
      //   component: OeAggregatorListComponent,
      // },
      // {
      //   path: ERoutes.Aggregators + '/:aggregatorId',
      //   component: OeAggregatorDetailComponent,
      // },
      {
        path: ERoutes.User_Management,
        component: OeUserManagementComponent,
      },
      {
        path: ERoutes.Heating_Branch_Management,
        component: OeHeatingBranchManagementComponent,
      },
      {
        path: ERoutes.Company_Management,
        component: OeCompanyManagementComponent,
      },
      {
        path: ERoutes.Group_Management,
        component: OeGroupManagementComponent,
      },
      {
        path: ERoutes.Group_Management + '/:groupId',
        component: OeServiceGroupDetailComponent,
      },
      {
        path: ERoutes.Consumption_Point_Management,
        component: OeConsumptionPointManagementComponent,
      },
      {
        path: ERoutes.Consumption_Point_Management + '/:consumptionPointId',
        component: OeConsumptionPointDetailComponent,
      },
      {
        path: ERoutes.User_Management + '/:userId',
        component: OeUserDetailComponent,
      },
      {
        path: ERoutes.Company_Management + '/:companyId',
        component: OeCompanyDetailComponent,
      },
      {
        path: ERoutes.Company_Management + '/:companyId/' + ERoutes.Spotbox_Management + '/:spotboxId',
        component: OeSpotBoxDetailComponent,
      },
      {
        path: ERoutes.Heating_Branch_Management + '/:serviceId',
        component: OeHeatingBranchDetailComponent,
      },
      {
        path: ERoutes.Device + '/:deviceId',
        component: OeDeviceDetailComponent,
      },
    ],
  },
  {
    path: ERoutes.Login,
    component: OeLoginComponent,
  },
];
