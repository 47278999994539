<div style='position: relative'>
  <mat-icon class='oe-close-icon' style='position: absolute; top: 10px; right: 10px' (click)='dialogRef.close()' fontIcon="close"></mat-icon>

  <div style='position: relative; top: 50px'>
    <h3 style='text-align: center; margin-bottom: 25px'>{{ this.data ? 'Upravit skupinu' : 'Přidat skupinu' }}</h3>

    <div style='display: flex; flex-wrap: wrap; margin: 0 30px; justify-content: space-between'>
      <mat-form-field style='width: 100%'>
        <mat-label>Jméno</mat-label>
        <input [(ngModel)]='group.name' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 100%'>
        <mat-label>Firma</mat-label>
        <mat-select [disabled]='data' [(value)]="group.companyId">
          <mat-option [value]="company.companyId" *ngFor='let company of companies'>{{ company.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style='display: flex; justify-content: center; margin-top: 15px'>
      <button
        (click)='dialogRef.close()'
        style='margin-right: 10px'
        mat-button color="primary">
        Zrušit
      </button>
      <button
        (click)='dialogRef.close(group)'
        style='margin-left: 10px'
        mat-raised-button color="primary">
        {{ this.data ? 'Upravit' : 'Přidat' }}
      </button>
    </div>
  </div>
</div>
