<div class='view-wrapper' style='margin: 30px 0' *ngIf='_state.boxDevice'>
  <div style='margin: 15px'>
    <div class='table-toolbar'>
      <button mat-stroked-button color="primary" style='margin-right: 12px' (click)='openHandleDialog(_state.boxDevice)'>
        Upravit SPOTBOX
        <mat-icon class='oe-primary-icon' fontIcon="edit" style='margin-right: 6px'></mat-icon>
      </button>

      <button mat-stroked-button color="primary" style='margin-right: 12px' (click)='openDeleteDialog(_state.boxDevice)'>
        Smazat SPOTBOX
        <mat-icon class='oe-primary-icon' fontIcon="delete" style='margin-right: 6px'></mat-icon>
      </button>

      <button mat-stroked-button color="primary" style='margin-right: 12px' (click)='testBoxConnection(_state.boxDevice)'>
        Otestovat spojení
        <mat-icon class='oe-primary-icon' fontIcon="rss_feed" style='margin-right: 6px'></mat-icon>
      </button>
    </div>
    <mat-card>
      <div class='m-3'>
        <div class='odd-row'><h5>Status</h5>
          <mat-icon *ngIf="_state.boxDevice.status === EBoxStatus.Connected" style='color: #5cb85c' fontIcon="check_circle"></mat-icon>
          <mat-icon *ngIf="_state.boxDevice.status === EBoxStatus.Error" style='color: #ed4337' fontIcon="cancel"></mat-icon>
          <mat-icon *ngIf="_state.boxDevice.status === EBoxStatus.Disconnected" style='color: #F7B217' fontIcon="warning"></mat-icon>
          <div style='margin-left: 8px;'>{{_state.boxDevice.message}}</div>
        </div>
        <div class='even-row'><h5>Jméno</h5>{{_state.boxDevice.name}}</div>
        <div class='odd-row'><h5>Poznámka</h5>{{_state.boxDevice.note}}</div>
        <div class='even-row'><h5>Odběrné místo</h5>{{_state.boxDevice.consumptionPoint}}</div>
        <div class='odd-row'><h5>Datum a čas poslední komunikace</h5>{{dayjs(_state.boxDevice.lastCommunication).format('DD.MM.YYYY, HH:mm:ss')}}</div>
        <div class='even-row'><h5>wsApiKey</h5>{{_state.boxDevice.wsApiKey}}</div>
      </div>
    </mat-card>
  </div>

  <div style='margin: 15px'>
    <div class='table-toolbar'>
      <button mat-stroked-button color="primary" style='margin-right: 12px' (click)='openServiceHandleDialog()'>
        Přidat službu
        <mat-icon class='oe-primary-icon' fontIcon="add" style='margin-right: 6px'></mat-icon>
      </button>
    </div>
    <oe-table
      *ngIf='tableSource'
      [data]='tableSource'
      (rowEdit)='openServiceHandleDialog($event)'
      (rowDelete)='openServiceDeleteDialog($event)'
      [hiddenColumns]="['deviceId', 'companyId', 'id']"
      (detailOpened)='_router.navigate([ERoutes.Main, ERoutes.Heating_Branch_Management, $event.id])'
      [pageSize]='10'>
    </oe-table>
  </div>
</div>
