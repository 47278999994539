export interface IUser {
  userId?: string;
  username?: string;
  email: string;
  role: EUserRole | undefined;
  companyId?: string;
  userName?: string;
  password?: string;
}

export enum EUserRole {
  Admin_Oe = 'AdminOE',
  Admin_Partner = 'AdminPartner',
}
