import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { STANDARD_OE_API_URL } from '../constants/standard-oe-api.constant';
import { IBoxDevice } from '../interfaces/box-service.interface';
import { IDevicesResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class BoxDeviceApiService {
  // INJECTS
  private _http = inject(HttpClient);

  public getBoxDeviceList(companyId?: string) {
    const body: any = {};
    if (companyId) {
      body.companyId = companyId;
    }

    return this._http.post<IDevicesResponse<IBoxDevice>>(`${STANDARD_OE_API_URL}/box/device/get-devices`, body);
  }

  public getBoxDevice(deviceId: string) {
    return this._http.post<IBoxDevice>(`${STANDARD_OE_API_URL}/box/device/get-device`, {
      deviceId: deviceId,
    });
  }

  public deleteBoxDevice(deviceId: string) {
    return this._http.post(`${STANDARD_OE_API_URL}/box/device/delete-device`, {
      deviceId: deviceId,
    });
  }

  public editBoxDevice(body: IBoxDevice) {
    return this._http.post<IBoxDevice>(`${STANDARD_OE_API_URL}/box/device/edit-device`, body);
  }

  public createBoxDevice(body: IBoxDevice) {
    return this._http.post<IBoxDevice>(`${STANDARD_OE_API_URL}/box/device/create-device`, body);
  }

  public testBoxDevice(deviceId: string) {
    return this._http.post<any>(`${STANDARD_OE_API_URL}/box/device/test-device`, {
      deviceId: deviceId,
    });
  }
}
