export enum ERoutes {
  Main = 'obzor-energy',
  Aggregators = 'aggregators',
  Login = 'login',
  Consumption_Point_Management = 'consumption-points',
  User_Management = 'users',
  Company_Management = 'companies',
  Group_Management = 'groups',
  Device = 'device',
  Dashboard = 'dashboard',
  SVR = 'svr',
  Heating_Branch_Management = 'heating-branch',
  Spotbox_Management = 'spotbox',
}
