export enum EToolbarHeader {
  User_Management = 'Správa uživatelů',
  Heating_Branch_Management = 'Řízení topných větví',
  Device = 'Zařízení',
  Aggregate_Block_List = 'Seznam agregačních bloků',
  Aggregate_Block = 'Agregační blok',
  User = 'Uživatel',
  Dashboard = 'Dashboard',
  SVR = 'svr',
}
