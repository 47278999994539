import { NgForOf, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { take } from 'rxjs';
import { ICompany } from '../../interfaces/company.interface';
import { EUserRole, IUser } from '../../interfaces/user.interface';
import { CompanyApiService } from '../../services/company-api.service';

@Component({
  standalone: true,
  selector: 'oe-user-handle-dialog',
  providers: [HttpClient],
  templateUrl: './oe-user-handle-dialog.component.html',
  styleUrls: ['./oe-user-handle-dialog.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    FormsModule,
    MatOption,
    MatSelect,
    NgForOf,
    NgIf,
  ],
})
export class OeUserHandleDialogComponent implements OnInit {
  // INJECTS
  public data = inject(MAT_DIALOG_DATA);
  public dialogRef = inject(MatDialogRef);
  private _company = inject(CompanyApiService);

  // VARIABLES
  public companyList!: ICompany[];
  public user: IUser = {
    companyId: this.data?.companyId || '',
    userName: this.data?.name || this.data?.username || '',
    password: this.data?.password || '',
    email: this.data?.mail || this.data?.email || '',
    role: this.data?.role || undefined,
  };

  // ENUMS
  protected readonly EUserRole = EUserRole;

  ngOnInit() {
    this._company
      .getCompanyList()
      .pipe(take(1))
      .subscribe(companies => {
        this.companyList = companies.list;
      });
  }
}
