<div style='margin: 30px 15px; min-width: max-content' *ngIf='showBody'>
  <div style='display: flex; justify-content: space-between;'>
    <div style='width: 49%'>
      <mat-card style='padding: 15px; text-align: center'>
        <h5>Celkové dispozice</h5>
        <h3 style='margin: 0'>{{ totalActualPower }} MW / {{ totalAvailablePower }} MW</h3>
      </mat-card>
    </div>

    <div style='width: 49%'>
      <mat-card style='height: 100%; text-align: center'>Výnosy</mat-card>
    </div>
  </div>

  <!--  DEVICES TABLE-->
  <div style='margin-top: 30px'>
    <div class='table-toolbar'>
      <h5 class='card-label' style='margin-bottom: 0; color: white'>Zařízení</h5>
    </div>
    <oe-table
      *ngIf='devicesTableSource'
      [data]='devicesTableSource'
      [hiddenColumns]="['deviceId']"
      (detailOpened)='_router.navigate([ERoutes.Main, ERoutes.Device, $event.deviceId])'>
    </oe-table>
  </div>

  <!--  CALENDAR TABLE-->
  <div style='margin-top: 30px'>
    <div class='table-toolbar'>
      <h5 class='card-label' style='margin-bottom: 0; color: white'>Dostupnost</h5>
    </div>
    <oe-table
      *ngIf='calendarTableSource'
      [data]='calendarTableSource'
      [hiddenColumns]="['deviceId']"
      (rowDelete)='deleteEvent($event.id, $event.from)'
      (rowEdit)="openEventDialog($event.from, $event.to)"
      (rowSelected)='openLogDetail($event)'>
    </oe-table>
  </div>

  <!--  LOGS TABLE-->
  <div style='margin-top: 30px'>
    <div class='table-toolbar'>
      <h5 class='card-label' style='margin-bottom: 0; color: white'>Log eventů</h5>
    </div>
    <oe-table
      *ngIf='logsTableSource'
      [data]='logsTableSource'
      [hiddenColumns]="['authorId', 'deviceId']"
      (rowSelected)='openLogDetail($event)'>
    </oe-table>
  </div>
</div>
