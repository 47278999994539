export interface ILoginResponse {
  apiKey: string;
  role: string;
}

export interface ILoginRequestBody {
  username: string;
  password: string;
}

export const Api_Token_Key = 'obzor-energy-api-k ey';
