import { JsonPipe, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { ERoutes } from '../../enums/oe-routes.enum';
import { EBoxStatus } from '../../interfaces/box-service.interface';
import { ColumnHeaderTranslate } from '../../pipes/table-column-header.pipe';

@Component({
  standalone: true,
  selector: 'oe-table',
  providers: [HttpClient],
  templateUrl: './oe-table.component.html',
  styleUrls: ['./oe-table.component.scss'],
  imports: [
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    NgForOf,
    NgIf,
    ColumnHeaderTranslate,
    NgSwitch,
    NgSwitchDefault,
    NgSwitchCase,
    MatButtonModule,
    JsonPipe,
    MatIconModule,
  ],
})
export class OeTableComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() data!: any;
  @Input() hiddenColumns!: string[];
  @Input() paging = true;
  @Input() pageSize = 5;

  @Output() rowSelected = new EventEmitter<any>();
  @Output() rowEdit = new EventEmitter<any>();
  @Output() rowDelete = new EventEmitter<any>();
  @Output() detailOpened = new EventEmitter<any>();

  public _router = inject(Router);

  // VARIABLES
  public dataSource!: any;
  public columns: string[] = [];

  // ENUMS
  protected readonly ERoutes = ERoutes;
  protected readonly EBoxStatus = EBoxStatus;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
    Object.keys(this.data[0]).forEach(key => {
      if (!this.hiddenColumns?.includes(key)) {
        this.columns.push(key);
      }
    });
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }
}
