import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { switchMap, take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { IBoxServiceGroup } from '../../interfaces/box-service-group.interface';
import { IBoxService } from '../../interfaces/box-service.interface';
import { EToastMessage, EToastType } from '../../interfaces/toast.interface';
import { BoxServiceApiService } from '../../services/box-service-api.service';
import { BoxServiceGroupApiService } from '../../services/box-service-group-api.service';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';
import { OeGroupHandleDialogComponent } from '../group-handle-dialog/oe-group-handle-dialog.component';
import { OeBoxServiceHandleDialogComponent } from '../oe-box-service-handle-dialog/oe-box-service-handle-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeJoinServiceWithGroupDialogComponent } from '../oe-join-service-with-group-dialog/oe-join-service-with-group-dialog.component';
import { OeTableComponent } from '../oe-table/oe-table.component';

@Component({
  standalone: true,
  selector: 'oe-service-group-detail',
  providers: [HttpClient],
  templateUrl: './oe-service-group-detail.component.html',
  styleUrls: ['./oe-service-group-detail.component.scss'],
  imports: [MatCardModule, NgIf, MatButton, MatIcon, OeTableComponent],
})
export class OeServiceGroupDetailComponent implements OnInit {
  // INJECTS
  public _state = inject(StateService);
  public _router = inject(Router);
  private _toast = inject(ToasterService);
  private _dialog = inject(MatDialog);
  private _boxService = inject(BoxServiceApiService);
  private _boxServiceGroup = inject(BoxServiceGroupApiService);

  // ENUMS
  protected readonly ERoutes = ERoutes;

  // VARIABLES
  public tableSource: any;

  ngOnInit() {
    this.setGroup();
  }

  private setGroup() {
    this.tableSource = null;
    const groupId = this._router.url.split('/')[3];
    this._boxServiceGroup
      .getServiceGroups()
      .pipe(
        take(1),
        switchMap(res => {
          this._state.group = res.list.find(g => g.serviceGroupId === groupId) as IBoxServiceGroup;

          return this._boxService.getServicesByGroup(this._state.group.serviceGroupId as string);
        })
      )
      .subscribe(response => {
        this.tableSource = response.services.map(s => {
          return {
            status: s.status === 'connected',
            id: s.serviceId,
            name: s.name,
            type: s.type,
            note: s.note,
            remove: true,
          };
        });
      });
  }

  public openGroupHandleDialog(e: IBoxServiceGroup) {
    this._dialog
      .open(OeGroupHandleDialogComponent, {
        height: '350px',
        width: '450px',
        data: e,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._boxServiceGroup
            .editServiceGroup(result.name, e.serviceGroupId as string)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.setGroup();
                this._toast.open(EToastType.Success, 'Skupina byla úspěšně upravena.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Skupinu nebylo možné upravit.');
              },
            });
        }
      });
  }

  public openServiceDeleteDialog(e: IBoxService) {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._boxServiceGroup
            .editServiceGroup(
              this._state.group?.name as string,
              this._state.group?.serviceGroupId as string,
              this._state.group?.serviceIds?.filter(s => s !== e.id)
            )
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.setGroup();
                this._toast.open(EToastType.Success, 'Služba byla úspěšně odebrána ze skupiny.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné odebrat ze skupiny.');
              },
            });
        }
      });
  }

  public openDeleteDialog(e: IBoxServiceGroup) {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._boxServiceGroup
            .deleteServiceGroup(e.serviceGroupId as string)
            .pipe(take(1))
            .subscribe({
              next: () => {
                void this._router.navigate([ERoutes.Main, ERoutes.Group_Management]);
                this._toast.open(EToastType.Success, 'Skupina byla úspěšně odstraněna.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Skupinu nebylo možné odstranit.');
              },
            });
        }
      });
  }

  public openAddServiceDialog() {
    this._dialog
      .open(OeJoinServiceWithGroupDialogComponent, {
        height: '260px',
        width: '500px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setGroup();
        }
      });
  }

  public openServiceHandleDialog(e?: IBoxService) {
    this._dialog
      .open(OeBoxServiceHandleDialogComponent, {
        height: '350px',
        width: '780px',
        data: { ...e, companyId: this._state.company?.companyId, showDeviceInput: false, showCompanyInput: false },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (!e) {
            this._boxService
              .createBoxService(result)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setGroup();
                  this._toast.open(EToastType.Success, EToastMessage.BoxService_Create_Success);
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné vytvořit.');
                },
              });
          } else {
            this._boxService
              .editBoxService({ ...result, serviceId: e.id })
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setGroup();
                  this._toast.open(EToastType.Success, 'Služba byla úspěšně upravena.');
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné upravit.');
                },
              });
          }
        }
      });
  }
}
