import { NgForOf, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { forkJoin, take } from 'rxjs';
import { EBoxServiceType, EBoxServiceTypeName, IBoxDevice, IBoxService } from '../../interfaces/box-service.interface';
import { ICompany } from '../../interfaces/company.interface';
import { BoxDeviceApiService } from '../../services/box-device-api.service';
import { CompanyApiService } from '../../services/company-api.service';

@Component({
  standalone: true,
  selector: 'oe-box-service-handle-dialog',
  providers: [HttpClient],
  templateUrl: './oe-box-service-handle-dialog.component.html',
  styleUrls: ['./oe-box-service-handle-dialog.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    FormsModule,
    MatOption,
    MatSelect,
    NgForOf,
    NgIf,
  ],
})
export class OeBoxServiceHandleDialogComponent implements OnInit {
  // INJECTS
  public data = inject(MAT_DIALOG_DATA);
  public dialogRef = inject(MatDialogRef);
  private _company = inject(CompanyApiService);
  private _boxDevice = inject(BoxDeviceApiService);

  // VARIABLES
  public companyList!: ICompany[];
  public deviceList: IBoxDevice[] = [];
  public box: IBoxService = {
    companyId: this.data?.companyId || '',
    deviceId: this.data?.deviceId || '',
    type: this.data?.type || '',
    serviceName: this.data?.serviceName || '',
    name: this.data?.name || '',
    note: this.data?.note || '',
  };

  // ENUMS
  protected readonly EBoxServiceType = EBoxServiceType;
  protected readonly EBoxServiceTypeName = EBoxServiceTypeName;

  ngOnInit() {
    this._company
      .getCompanyList()
      .pipe(take(1))
      .subscribe(companies => {
        this.companyList = companies.list;

        const requests = companies.list.map(company => {
          return this._boxDevice.getBoxDeviceList(company.companyId as string);
        });

        forkJoin(requests)
          .pipe(take(1))
          .subscribe(response => {
            response.forEach(list => {
              list.devices.forEach(device => {
                this.deviceList.push(device);
              });
            });
          });
      });
  }

  public setServiceNamePreset(e: EBoxServiceType) {
    if (e === EBoxServiceType.HeatingBranch) {
      this.box.serviceName = 'heatingBranch_';
    } else if (e === EBoxServiceType.PumpHeatingBranch) {
      this.box.serviceName = 'pumpHeatingBranch_';
    } else if (e === EBoxServiceType.HotWaterTank) {
      this.box.serviceName = 'hotWaterTank_';
    } else if (e === EBoxServiceType.PowerMeter) {
      this.box.serviceName = 'powerMeter_';
    } else if (e === EBoxServiceType.AssociatedPowerMeter) {
      this.box.serviceName = 'associatedPowerMeter_';
    } else if (e === EBoxServiceType.WaterMeterHot) {
      this.box.serviceName = 'waterMeterHot_';
    } else if (e === EBoxServiceType.WaterMeterCold) {
      this.box.serviceName = 'waterMeterCold_';
    } else if (e === EBoxServiceType.Calorimeter) {
      this.box.serviceName = 'calorimeter_';
    }
  }
}
