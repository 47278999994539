<div style='display: flex; flex-wrap: wrap; margin: 15px'>
  <mat-card (click)='_router.navigate([ERoutes.Main, ERoutes.SVR])'>
    <h5 style='text-align: center'>Služby výkonové rovnováhy</h5>
    <div class='m-3'>
      Přenosová soustava, kterou provozuje ČEPS a.s. potřebuje flexibilní záložní zařízení, které jsou schopné reagovat na odchylku v soustavě a poskytovat tak služby výkonové rovnováhy. V případě povelu = aktivace služby pak dochází ke zvýšení nebo snížení plánované výroby nebo spotřeby oproti plánu.
      Svoji dostupnou flexibilitu s námi můžete nabídnout jako placenou službu a zajistit si tak dodatečný příjem.
    </div>

    <a style='text-align: center' [href]="'https://obzorenergy.cz/obzor-energy-titulni-strana/produkty/sluzba-vykonove-rovnovahy/'" target='_blank'>Dozvědět se více</a>
  </mat-card>

  <mat-card>
    <h5 style='text-align: center'>Řízení SPOT cenami</h5>
    <div class='m-3'>
      Výrazně snižuje náklady na elektrickou energii. Nabízené řešení umožňuje plně využívat výhod rozdílných cen energií v průběhu dne. Jedná se o vyspělý technologický komplex optimalizace nákupní strategie zákazníka, kdy vlastní nákup za spotové ceny je pouze jednou složkou produktu. Řešení vyhodnotí zda a kdy je výhodnější spouštět tepelná čerpadla nebo využívat přímo odběr elektrické energie. Řídí vhodné zvyšování akumulace a to jak v napojení na TUV nádrže, akumulace v topných větvích nebo naopak aktivuje záložní zdroje energií.
    </div>

    <a style='text-align: center' [href]="'https://obzorenergy.cz/obzor-energy-titulni-strana/produkty/rizeni-spot-cenami/'" target='_blank'>Dozvědět se více</a>
  </mat-card>

  <mat-card>
    <h5 style='text-align: center'>Řízení více zdrojů energie</h5>
    <div class='m-3'>
      V případě více zdrojů tepla (např. plyn nebo tepelné čerpadlo) je vždy zvolen ten s nižšími náklady. Tepelné čerpadlo, elektro-kotel nebo topná tyč v akumulační nádrži je aktivována podle situace na SPOTu tedy mimo sítě také z FVE případně baterie či větrné elektrárny. Řízení probíhá v napojení na centrální systém se vzdálenou kontrolou a navazuje na Řízení SPOT cenou.
    </div>

    <a style='text-align: center' [href]="'https://obzorenergy.cz/obzor-energy-titulni-strana/produkty/rizeni-vice-zdroju-energie/'" target='_blank'>Dozvědět se více</a>
  </mat-card>

  <mat-card>
    <h5 style='text-align: center'>Řízení 1/4 hodinového maxima</h5>
    <div class='m-3'>
      Služba je určena pro velkoodběry, kde v případě přesažení ¼ hodinového maxima dojde k penalizaci. Řešení pomáhá sledovat aktuální spotřebu v každé sekundě dané ¼ hodiny a v případě, že se očekávaná spotřeba blíží nastavené hodnotě, začneme v nastaveném pořadí dle priorit odpojovat spotřebiče tak, aby byl zachován plynulý a optimální provoz. Řešení je plně automatizováno a umožňuje nastavit dle konkrétních potřeb.
    </div>

    <a style='text-align: center' [href]="'https://obzorenergy.cz/obzor-energy-titulni-strana/produkty/reseni-1-4-maxim/'" target='_blank'>Dozvědět se více</a>
  </mat-card>

  <mat-card>
    <h5 style='text-align: center'>Řízení topných větví</h5>
    <div class='m-3'>
      Napojení automatizace na topné větve zvyšuje efektivitu řízení tepla v topných větvích. Pokud provoz využívá akumulační nádrže, tak větev topného systému vyžaduje nižší teploty než má akumulační nádrž. Řešení s využitím trojcestných směsných ventilů zajistí stabilní teploty na výstupu. Řešení opět navyšuje možnosti akumulace, zlevňuje provoz a umožňuje napojení na Řízení SPOT cenou.
    </div>

    <a style='text-align: center' [href]="'https://obzorenergy.cz/obzor-energy-titulni-strana/produkty/rizeni-topnych-vetvi/'" target='_blank'>Dozvědět se více</a>
  </mat-card>

  <mat-card>
    <h5 style='text-align: center'>Řízení fotovoltaické elektrárny</h5>
    <div class='m-3'>
      Dosažení maximálního využití vlastní vyrobené energie přichází se současným provozem výroby a spotřeby. Podle aktuálního přebytku energie řídíme důležité energetické zařízení. Pokud se objeví záporné ceny energie nebo narušení v distribuční síti, je nutné omezit výkon výroby. Připojení na centrální systém umožňuje nejen načasování využívání v hodinách s nejvyšší cenou dle SPOTu, ale také přesměrování na vhodné způsoby akumulace.
    </div>

    <a style='text-align: center' [href]="'https://obzorenergy.cz/obzor-energy-titulni-strana/produkty/rizeni-fve/'" target='_blank'>Dozvědět se více</a>
  </mat-card>

  <mat-card>
    <h5 style='text-align: center'>Výkonová predikce větrných elektráren</h5>
    <div class='m-3'>
      Zvažujete instalaci malé větrné elektrárny a nejste si jistí ekonomickou návratností?
      Nainstalujeme Vám do místa předpokládané instalace meteostanici a porovnáme aktuální sílu větru s cenou el na SPOTu. Dostanete tak reálná data a ceny, které může Vaše větrná elektrárna ušetřit.
    </div>

    <a style='text-align: center' [href]="'https://obzorenergy.cz/obzor-energy-titulni-strana/produkty/predikce-vetrnych-elektraren/'" target='_blank'>Dozvědět se více</a>
  </mat-card>
</div>
