import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { EToastType } from '../../interfaces/toast.interface';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';
import { UserApiService } from '../../services/user-api.service';
import { OeChangePasswordDialogComponent } from '../oe-change-password-dialog/oe-change-password-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeUserHandleDialogComponent } from '../oe-user-handle-dialog/oe-user-handle-dialog.component';

@Component({
  standalone: true,
  selector: 'oe-user-detail',
  providers: [HttpClient],
  templateUrl: './oe-user-detail.component.html',
  styleUrls: ['./oe-user-detail.component.scss'],
  imports: [MatCardModule, NgIf, MatButton, MatIcon],
})
export class OeUserDetailComponent {
  // INJECTS
  public _state = inject(StateService);
  private _toast = inject(ToasterService);
  private _router = inject(Router);
  private _user = inject(UserApiService);
  private _dialog = inject(MatDialog);

  constructor() {
    this.getUser();
  }

  private getUser() {
    const routes = this._router.url.split('/');
    const userId = routes[routes.indexOf(ERoutes.User_Management) + 1];
    this._user
      .getUserList()
      .pipe(take(1))
      .subscribe(users => {
        const user = users.list.find(user => user.userId === userId);
        if (!user) {
          this._toast.open(EToastType.Danger, '', 'bottom-center', 'Uživatel nenalezen.');
          void this._router.navigate([ERoutes.Main, ERoutes.User_Management]);
        } else {
          this._state.user = user;
        }
      });
  }

  public openPasswordChangeDialog() {
    this._dialog
      .open(OeChangePasswordDialogComponent, {
        height: '450px',
        width: '450px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._user
            .changePassword(result.old, result.new)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this._toast.open(EToastType.Success, 'Heslo bylo úspěšně změněno.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Heslo nebylo možné změnit.');
              },
            });
        }
      });
  }

  public openDeleteDialog() {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this._user
            .deleteUser(this._state.user?.userId as string)
            .pipe(take(1))
            .subscribe({
              next: () => {
                void this._router.navigate([ERoutes.Main, ERoutes.User_Management]);
                this._toast.open(EToastType.Success, 'Uživatel byl úspěšně smazán.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Uživatele nebylo možné smazat.');
              },
            });
        }
      });
  }

  public openUserHandleDialog() {
    this._dialog
      .open(OeUserHandleDialogComponent, {
        height: '350px',
        width: '780px',
        data: this._state.user,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._user
            .editUser({ ...result, userId: this._state.user?.userId })
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.getUser();
                this._toast.open(EToastType.Success, 'Uživatel byl úspěšně upraven.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Uživatele nebylo možné upravit.');
              },
            });
        }
      });
  }
}
