export enum EToastType {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
}

// TODO: use EToastMessage through app
export enum EToastMessage {
  Availability_Remove_Success = 'Dostupnost byla úspěšně odstraněna.',
  Availability_Remove_Failure = 'Pokus o smazání dostupnosti byl neúspěšný.',
  Availability_Add_Success = 'Dostupnost byla úspěšně přidána.',
  Availability_Add_Failure = 'Pokus o přidání dostupnosti byl neúspěšný.',
  Availability_Edit_Success = 'Dostupnost byla úspěšně upravena.',
  Availability_Edit_Failure = 'Pokus o úpravu dostupnosti byl neúspěšný.',
  BoxService_Create_Success = 'Služba byla úspěšně vytvořena.',
  Heating_Branch_Time_Settings_Update_Success = 'Časové nastavení řízení topné větve bylo úspěšně upraveno.',
  Heating_Branch_Time_Settings_Update_Failure = 'Pokus o úpravu časového nastavení řízení topné větve byl neuspěšný.',
  Heating_Branch_Values_Settings_Update_Success = 'Nastavení hodnot řízení topné větve bylo úspěšně upraveno.',
  Heating_Branch_Values_Settings_Update_Failure = 'Pokus o úpravu nastavení hodnot řízení topné větve byl neuspěšný.',
}
