import { NgForOf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import { EHeatingBranchEquithermType, IHeatingBranchDailySettings } from '../../interfaces/heating-branch.interface';

@Component({
  standalone: true,
  selector: 'oe-heating-branch-daily',
  providers: [HttpClient],
  templateUrl: './oe-heating-branch-daily.component.html',
  styleUrls: ['./oe-heating-branch-daily.component.scss'],
  imports: [MatFormField, MatSelect, MatOption, MatLabel, MatCheckbox, NgForOf, FormsModule],
})
export class OeHeatingBranchDailyComponent {
  @Input()
  public set settings(e: IHeatingBranchDailySettings) {
    this.sequences = e.hourSettings.map((setting, index) => {
      return {
        label:
          (index.toString().length < 2 ? '0' + index + ' - ' + '0' + (index + 1) : index + ' - ' + (index + 1)) + 'h',
        checked: false,
        value: setting,
      };
    });
  }

  @Output() settingsChanged = new EventEmitter<
    { label: string; checked: boolean; value: EHeatingBranchEquithermType }[]
  >();

  // // VARIABLES
  public sequences: { label: string; checked: boolean; value: EHeatingBranchEquithermType }[] = [];
  public allComplete: boolean = false;
  public someComplete: boolean = false;

  // ENUMS
  protected readonly EHeatingBranchEquithermType = EHeatingBranchEquithermType;

  public setAll(checked: boolean) {
    this.allComplete = checked;
    this.sequences.forEach(s => (s.checked = checked));
    this.someComplete = this.sequences.filter(t => t.checked).length > 0 && !this.allComplete;
  }

  public updateAllChecked() {
    this.allComplete = this.sequences != null && this.sequences.every(t => t.checked);
    this.someComplete = this.sequences.filter(t => t.checked).length > 0 && !this.allComplete;
  }

  public setCheckedValues(e: MatSelectChange) {
    this.sequences.forEach(s => {
      if (s.checked) {
        s.value = e.value;
      }
    });
  }

  public updateSettings() {
    this.settingsChanged.emit(this.sequences);
  }
}
