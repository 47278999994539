import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { IBoxServiceGroup } from '../../interfaces/box-service-group.interface';
import { EToastType } from '../../interfaces/toast.interface';
import { BoxServiceGroupApiService } from '../../services/box-service-group-api.service';
import { ToasterService } from '../../services/toaster.service';
import { OeGroupHandleDialogComponent } from '../group-handle-dialog/oe-group-handle-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeTableComponent } from '../oe-table/oe-table.component';

@Component({
  standalone: true,
  selector: 'oe-group-management',
  providers: [HttpClient],
  templateUrl: './oe-group-management.component.html',
  styleUrls: ['./oe-group-management.component.scss'],
  imports: [MatButton, MatIcon, NgIf, OeTableComponent],
})
export class OeGroupManagementComponent implements OnInit {
  // INJECTS
  private _boxServiceGroup = inject(BoxServiceGroupApiService);
  private _toast = inject(ToasterService);
  private _dialog = inject(MatDialog);
  public _router = inject(Router);

  // VARIABLES
  public tableSource!: any;

  // ENUMS
  protected readonly ERoutes = ERoutes;

  ngOnInit() {
    this.setupTable();
  }

  private setupTable() {
    this.tableSource = null;

    this._boxServiceGroup
      .getServiceGroups()
      .pipe(take(1))
      .subscribe({
        next: res => {
          this.tableSource = res.list.map(g => {
            return {
              name: g.name,
              serviceGroupId: g.serviceGroupId,
              detail: true,
              edit: true,
              remove: true,
            };
          });
        },
        error: err => {
          void this._router.navigate([ERoutes.Main]);
          this._toast.open(EToastType.Danger, err.error.message || 'Skupiny nebylo možné načíst.');
        },
      });
  }

  public openDeleteDialog(e: IBoxServiceGroup) {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._boxServiceGroup
            .deleteServiceGroup(e.serviceGroupId as string)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.setupTable();
                this._toast.open(EToastType.Success, 'Skupina byla úspěšně odstraněna.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Skupinu nebylo možné odstranit.');
              },
            });
        }
      });
  }

  public openGroupHandleDialog(e?: IBoxServiceGroup) {
    this._dialog
      .open(OeGroupHandleDialogComponent, {
        height: '350px',
        width: '450px',
        data: e,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (e) {
            this._boxServiceGroup
              .editServiceGroup(result.name, e.serviceGroupId as string)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setupTable();
                  this._toast.open(EToastType.Success, 'Skupina byla úspěšně upravena.');
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Skupinu nebylo možné upravit.');
                },
              });
          } else {
            this._boxServiceGroup
              .createServiceGroup(result.name, result.companyId)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setupTable();
                  this._toast.open(EToastType.Success, 'Skupina byla úspěšně vytvořena.');
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Skupinu nebylo možné vytvořit.');
                },
              });
          }
        }
      });
  }
}
