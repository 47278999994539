import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MaterialService {
  public formatPower(value: string) {
    return value + ' kw';
  }

  public formatHeat(value: string) {
    return value + ' °C';
  }
}
