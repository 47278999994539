<div style='position: relative'>
  <mat-icon class='oe-close-icon' style='position: absolute; top: 10px; right: 10px' (click)='dialogRef.close()' fontIcon="close"></mat-icon>

  <div style='display: grid; margin: 0 66px'>
    <h3 style='text-align: center; margin: 30px 0 25px 0'>{{ dateTo ? 'Upravit dostupnost' : 'Nastavit dostupnost' }}</h3>

    <mat-form-field class='w-100'>
      <mat-label>Od</mat-label>
      <input [(ngModel)]='dateFrom' [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" matInput>
    </mat-form-field>
    <owl-date-time [firstDayOfWeek]='1' [stepMinute]='60' #dt1></owl-date-time>

    <mat-form-field class='w-100'>
      <mat-label>Do</mat-label>
      <input [min]='dateFrom' [(ngModel)]='dateTo' [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" matInput>
    </mat-form-field>
    <owl-date-time [firstDayOfWeek]='1' [stepMinute]='60' #dt2></owl-date-time>


    <mat-form-field>
      <mat-label>Dostupnost</mat-label>
      <mat-select [disabled]="false" [value]="availability || 'unavailable'">
        <mat-option (click)="availability = EDeviceAvailability.Available" [value]="EDeviceAvailability.Available">Dostupný</mat-option>
        <mat-option (click)="availability = EDeviceAvailability.Unavailable" [value]="EDeviceAvailability.Unavailable">Nedostupný</mat-option>
      </mat-select>
    </mat-form-field>

    <div style='display: flex; justify-content: center'>
      <button
        (click)='dialogRef.close()'
        style='margin-right: 10px'
        mat-button color="primary">
        Zrušit
      </button>
      <button
        (click)='dialogRef.close({ from: this.dateFrom, to: this.dateTo, type: this.availability })'
        [disabled]='!dateFrom || !dateTo || !availability'
        style='margin-left: 10px'
        mat-raised-button color="primary">
        Nastavit
      </button>
    </div>
  </div>
</div>
