<ng-container>
  <mat-toolbar color="primary" style='border-bottom: 1px solid white'>
    <span style='font-size: 12px; font-style: italic'>{{ version }}</span>
<!--    <button mat-icon-button [matMenuTriggerFor]="menu" class="example-icon" aria-label="Example icon-button with menu icon">-->
<!--      <mat-icon>menu</mat-icon>-->
<!--    </button>-->
<!--    <mat-menu #menu="matMenu">-->
<!--      <button mat-menu-item (click)='_router.navigate([ERoutes.Main, ERoutes.Aggregators])'>-->
<!--        <mat-icon>view_list</mat-icon>-->
<!--        <span>Seznam agregačních bloků</span>-->
<!--      </button>-->

<!--      <button mat-menu-item>-->
<!--        <mat-icon>dashboard</mat-icon>-->
<!--        <span>ČEPS dashboard</span>-->
<!--      </button>-->

<!--      <button mat-menu-item (click)='_router.navigate([ERoutes.Main, ERoutes.User_Management])'>-->
<!--        <mat-icon>supervisor_account</mat-icon>-->
<!--        <span>Správa uživatelů</span>-->
<!--      </button>-->

<!--      <button mat-menu-item>-->
<!--        <mat-icon>lock</mat-icon>-->
<!--        <span>Změna hesla</span>-->
<!--      </button>-->

<!--      <button mat-menu-item (click)='_auth.logout()'>-->
<!--        <mat-icon>logout</mat-icon>-->
<!--        <span>Odhlásit</span>-->
<!--      </button>-->
<!--    </mat-menu>-->

    <div style='display: flex; width: 100%; align-items: center'>
      <span class='oe-toolbar-header' style='flex: 1 0 0'>
        {{' ' || _state.header}}
        <div class='breadcrumbs-menu'>
          <div class='breadcrumb-item' (click)='_router.navigate([ERoutes.Main, ERoutes.Dashboard])'>
            Dashboard
          </div>
          <div
            class='breadcrumb-separator'
            *ngIf="_router.url.split('/').length > 2 && !_router.url.split('/').includes(ERoutes.Dashboard)">
            >
          </div>
          <div
            class='breadcrumb-item'
            (click)='_router.navigate([ERoutes.Main, ERoutes.Consumption_Point_Management])'
            *ngIf="_router.url.split('/').includes(ERoutes.Consumption_Point_Management)">
            Správa odběrných míst
          </div>
          <div
            class='breadcrumb-item'
            (click)='_router.navigate([ERoutes.Main, ERoutes.User_Management])'
            *ngIf="_router.url.split('/').includes(ERoutes.User_Management)">
            Správa uživatelů
          </div>
          <div
            class='breadcrumb-item'
            (click)='_router.navigate([ERoutes.Main, ERoutes.Company_Management])'
            *ngIf="_router.url.split('/').includes(ERoutes.Company_Management)">
            Správa firem
          </div>
          <div
            class='breadcrumb-item'
            (click)='_router.navigate([ERoutes.Main, ERoutes.Group_Management])'
            *ngIf="_router.url.split('/').includes(ERoutes.Group_Management)">
            Správa skupin
          </div>
          <div
            class='breadcrumb-item'
            (click)='_router.navigate([ERoutes.Main, ERoutes.Heating_Branch_Management])'
            *ngIf="_router.url.split('/').includes(ERoutes.Heating_Branch_Management)">
            Řízení topných větví
          </div>
          <div
            class='breadcrumb-item'
            (click)='_router.navigate([ERoutes.Main, ERoutes.SVR])'
            *ngIf="_router.url.split('/').includes(ERoutes.SVR) || _router.url.split('/').includes(ERoutes.Device)">
            Služby výkonové rovnováhy
          </div>
          <div class='breadcrumb-item' *ngIf="false">Řízení SPOT cenami</div>
          <div class='breadcrumb-item' *ngIf="false">Řízení více zdrojů energie</div>
          <div class='breadcrumb-item' *ngIf="false">Řízení 1/4 hodinového maxima</div>
          <div class='breadcrumb-item' *ngIf="false">Řízení topných větví</div>
          <div class='breadcrumb-item' *ngIf="false">Řízení fotovoltaické elektrárny</div>
          <div class='breadcrumb-item' *ngIf="false">Výkonová predikce větrných elektráren</div>
          <div
            class='breadcrumb-separator'
            *ngIf="_router.url.split('/').length > 3">
            >
          </div>
          <div
            class='breadcrumb-item'
            *ngIf="_router.url.split('/').includes(ERoutes.User_Management) && _router.url.split('/').length > 3">
            {{ _state.user?.username }}
          </div>
          <div
            class='breadcrumb-item'
            (click)='_router.navigate([ERoutes.Main, ERoutes.Company_Management, _state.company?.companyId])'
            *ngIf="_router.url.split('/').includes(ERoutes.Company_Management) && _router.url.split('/').length > 3">
            {{ _state.company?.name }}
          </div>
          <div
            class='breadcrumb-item'
            *ngIf="_router.url.split('/').includes(ERoutes.Device) && _router.url.split('/').length > 3">
            {{ _state.device?.deviceName }}
          </div>
          <div
            class='breadcrumb-item'
            *ngIf="_router.url.split('/').includes(ERoutes.Consumption_Point_Management) && _router.url.split('/').length > 3">
            {{ _state.consumptionPoint?.consumptionPointId }}
          </div>
          <div
            class='breadcrumb-item'
            *ngIf="_router.url.split('/').includes(ERoutes.Heating_Branch_Management) && _router.url.split('/').length > 3">
            {{ _state.boxService?.name }}
          </div>
          <div
            class='breadcrumb-item'
            *ngIf="_router.url.split('/').includes(ERoutes.Group_Management) && _router.url.split('/').length > 3">
            {{ _state.group?.name }}
          </div>
          <div
            class='breadcrumb-separator'
            *ngIf="_router.url.split('/').length > 5">
            >
          </div>
          <div
            class='breadcrumb-item'
            *ngIf="_router.url.split('/').includes(ERoutes.Spotbox_Management) && _router.url.split('/').length > 5">
            {{ _state.boxDevice?.name }}
          </div>
        </div>
      </span>

<!--      <ng-container *ngIf='_state.header === EToolbarHeader.Device'>-->
<!--        <button mat-button style='margin-right: 4px'>-->
<!--          <mat-icon fontIcon="settings"></mat-icon>-->
<!--          Konfigurace zařízení-->
<!--        </button>-->

<!--        <button mat-button>-->
<!--          <mat-icon fontIcon="add"></mat-icon>-->
<!--          Přidat zařízení-->
<!--        </button>-->
<!--      </ng-container>-->

      <ng-container>
        <button mat-button style='margin-right: 4px'>
          <mat-icon matBadge="7" matBadgeColor="warn" style='margin-right: 0' fontIcon="notifications"></mat-icon>
        </button>

        <button mat-button style='margin-right: 4px'>
          <mat-icon style='margin-right: 0' fontIcon="email"></mat-icon>
        </button>

        <button mat-button style='margin-right: 4px'>
          <mat-icon style='margin-right: 0' fontIcon="person"></mat-icon>
        </button>
      </ng-container>
    </div>
  </mat-toolbar>
</ng-container>
