import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { ICompany } from '../../interfaces/company.interface';

@Component({
  standalone: true,
  selector: 'oe-company-handle-dialog',
  providers: [HttpClient],
  templateUrl: './oe-company-handle-dialog.component.html',
  styleUrls: ['./oe-company-handle-dialog.component.scss'],
  imports: [MatButtonModule, MatIconModule, MatFormField, MatInput, MatLabel, ReactiveFormsModule, FormsModule],
})
export class OeCompanyHandleDialogComponent {
  // INJECTS
  public data = inject(MAT_DIALOG_DATA);
  public dialogRef = inject(MatDialogRef);

  // VARIABLES
  public company: ICompany = {
    name: this.data?.name || '',
    address: this.data?.address || '',
    ic: this.data?.ic || '',
    dic: this.data?.dic || '',
    email: this.data?.email || '',
    phone: this.data?.phone || '',
  };
}
