import { ICompany } from './company.interface';
import { EUserRole, IUser } from './user.interface';

export interface IDevice {
  deviceId: string;
  name: string;
  actualPower: number;
}

export interface IChartData {
  timeX: string[];
  powerY: number[];
}

export interface IDeviceOwner {
  userId: string;
  username: string;
  email: string;
  role: EUserRole;
}

export interface IDeviceActualFullData {
  powerFactorEl: number;
  mfrr5SKUT: number;
  pvs: number;
  actElEnergyEl: number;
  reactElEnergyEl: number;
  actPowerEl: number;
  reactPowerEl: number;
  apparentPowerEl: number;
  frequencyEl: number;
  voltageAcEl: number;
  currentAcEl: number;
  voltageL1El: number;
  voltageL2El: number;
  voltageL3El: number;
  temp: number;
  actPower: number;
  operationHoursDA: number;
  fuelLevelDA: number;
  operatingStates: number;
  errStates: number;
  operatingStatesDA: number;
  errStatesDA: number;
  pskut: number;
  pdgtrend: number;
  mfrr5Plus: number;
}

export interface IDeviceActualSimpleData {
  mfrr5SKUT: number;
  pvs: number;
  pskut: number;
  pdgtrend: number;
  mfrr5Plus: number;
}

export interface IDeviceConfig {
  startReadAddress: number;
  activationBit: number;
}

export interface IDeviceDetail {
  deviceName: string;
  ownerUser: IDeviceOwner;
  company: ICompany;
  actualFullData: IDeviceActualFullData;
  actualSimpleData: IDeviceActualSimpleData;
  config: IDeviceConfig;
}

export interface IDeviceEvent {
  dateTime: string | Date;
  eventType: string;
  userId: string;
  detail: string;
  dateSort?: number;
  user: IUser;
}

export interface IDeviceAvailability {
  id: string;
  from: string | Date;
  to: string | Date;
  type: EDeviceAvailability;
  deviceId: string;
}

export enum EDeviceAvailability {
  Available = 'available',
  Unavailable = 'notAvailable',
}
