import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { STANDARD_OE_API_URL } from '../constants/standard-oe-api.constant';
import { ERoutes } from '../enums/oe-routes.enum';
import { Api_Token_Key, ILoginRequestBody, ILoginResponse } from '../interfaces/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  // INJECTS
  private _http = inject(HttpClient);
  private _router = inject(Router);

  public authorize(body: ILoginRequestBody) {
    return this._http.post<ILoginResponse>(`${STANDARD_OE_API_URL}/auth/login`, body);
  }

  public logout() {
    void this._router.navigate([ERoutes.Login]);
    localStorage.setItem(Api_Token_Key, '');
  }

  public getAppVersion() {
    return this._http.get<string>(`${STANDARD_OE_API_URL}/health/get-version`);
  }
}
