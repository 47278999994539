export interface IBoxService {
  deviceId: string;
  deviceName?: string;
  companyId?: string;
  type: EBoxServiceType;
  serviceName: string;
  groupName?: string;
  name: string;
  note: string;
  serviceId?: string;
  id?: string;
  status?: EBoxStatus;
}

export interface IBoxDevice {
  deviceId: string;
  status: EBoxStatus;
  name: string;
  consumptionPoint: string;
  error?: 'connection error';
  note: string;
  message?: string;
  lastCommunication?: string;
  wsApiKey?: string;
}

export enum EBoxStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Error = 'error',
}

export enum EBoxServiceType {
  HeatingBranch = 'heatingBranch',
  PumpHeatingBranch = 'pumpHeatingBranch',
  HotWaterTank = 'hotWaterTank',
  PowerMeter = 'powerMeter',
  AssociatedPowerMeter = 'associatedPowerMeter',
  WaterMeterHot = 'waterMeterHot',
  WaterMeterCold = 'waterMeterCold',
  Calorimeter  = 'calorimeter',
}

export enum EBoxServiceTypeName {
  HeatingBranch = 'Topná větev',
  PumpHeatingBranch = 'Čerpadlová topná větev',
  HotWaterTank = 'Tepelná nádrž',
  PowerMeter = 'Elektroměr',
  AssociatedPowerMeter = 'Přidružený elektroměr',
  WaterMeterHot = 'Vodoměr teplá voda',
  WaterMeterCold = 'Vodoměr studená voda',
  Calorimeter  = 'Kaliometr',
}

export const serviceTypeTranslation: { [key in EBoxServiceType]: EBoxServiceTypeName } = {
  [EBoxServiceType.HeatingBranch]: EBoxServiceTypeName.HeatingBranch,
  [EBoxServiceType.PumpHeatingBranch]: EBoxServiceTypeName.PumpHeatingBranch,
  [EBoxServiceType.HotWaterTank]: EBoxServiceTypeName.HotWaterTank,
  [EBoxServiceType.PowerMeter]: EBoxServiceTypeName.PowerMeter,
  [EBoxServiceType.AssociatedPowerMeter]: EBoxServiceTypeName.AssociatedPowerMeter,
  [EBoxServiceType.WaterMeterHot]: EBoxServiceTypeName.WaterMeterHot,
  [EBoxServiceType.WaterMeterCold]: EBoxServiceTypeName.WaterMeterCold,
  [EBoxServiceType.Calorimeter]: EBoxServiceTypeName.Calorimeter,
};


