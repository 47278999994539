import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBoxServiceGroup } from '../interfaces/box-service-group.interface';
import { IBoxDevice, IBoxService } from '../interfaces/box-service.interface';
import { ICompany } from '../interfaces/company.interface';
import { IConsumptionPoint } from '../interfaces/consumption-point.interface';
import { IDeviceDetail } from '../interfaces/device.interface';
import { IHeatingBranchDailySettings, IHeatingBranchSettings } from '../interfaces/heating-branch.interface';
import { EToolbarHeader } from '../interfaces/state.interface';
import { IUser } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  // VARIABLES
  public header: EToolbarHeader | undefined;
  public user: IUser | undefined;
  public company: ICompany | undefined;
  public consumptionPoint: IConsumptionPoint | undefined;
  public device: IDeviceDetail | undefined;
  public boxService: IBoxService | undefined;
  public boxDevice: IBoxDevice | undefined;
  public group: IBoxServiceGroup | undefined;
  public chartColorScheme: any = {
    domain: ['#E06666', '#008000', '#FFFF00', '#0000FF', '#FF0000'],
  };

  // SUBJECTS
  public oldHeatingBranchSetting$ = new BehaviorSubject<IHeatingBranchSettings | undefined>(undefined);
  public copiedHeatingBranchDailySettings$ = new BehaviorSubject<IHeatingBranchDailySettings | undefined>(undefined);
}
