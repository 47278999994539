import { NgForOf, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { take } from 'rxjs';
import { IBoxService } from '../../interfaces/box-service.interface';
import { EToastType } from '../../interfaces/toast.interface';
import { BoxServiceApiService } from '../../services/box-service-api.service';
import { BoxServiceGroupApiService } from '../../services/box-service-group-api.service';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
  standalone: true,
  selector: 'oe-join-service-with-group-dialog',
  providers: [HttpClient],
  templateUrl: './oe-join-service-with-group-dialog.component.html',
  styleUrls: ['./oe-join-service-with-group-dialog.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    FormsModule,
    MatOption,
    MatSelect,
    NgForOf,
    NgIf,
  ],
})
export class OeJoinServiceWithGroupDialogComponent implements OnInit {
  // INJECTS
  public data = inject(MAT_DIALOG_DATA);
  public dialogRef = inject(MatDialogRef);
  public _service = inject(BoxServiceApiService);
  public _boxServiceGroup = inject(BoxServiceGroupApiService);
  private _toast = inject(ToasterService);
  private _state = inject(StateService);

  // VARIABLES
  public services!: IBoxService[];
  public value!: string[];

  ngOnInit() {
    this._service
      .getBoxServiceList(undefined, this._state.company?.companyId)
      .pipe(take(1))
      .subscribe(res => {
        this.services = res.services.filter(s => !this._state.group?.serviceIds?.includes(s.serviceId as string));
      });
  }

  public confirm() {
    this._boxServiceGroup
      .editServiceGroup(
        this._state.group?.name as string,
        this._state.group?.serviceGroupId as string,
        this._state.group?.serviceIds?.concat(this.value)
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
          this._toast.open(
            EToastType.Success,
            this.value.length > 1 ? 'Služby byly úspěšně přiřazeny.' : 'Služba byla úspěšně přiřazena.'
          );
        },
        error: err => {
          this.dialogRef.close(false);
          this._toast.open(
            EToastType.Danger,
            err.error.message ||
              (this.value.length > 1 ? 'Služby nebylo možné přiřadit.' : 'Službu nebylo možné přiřadit.')
          );
        },
      });
  }
}
