import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { Api_Token_Key } from '../../interfaces/auth.interface';
import { EToastType } from '../../interfaces/toast.interface';
import { AuthApiService } from '../../services/auth-api.service';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
  standalone: true,
  selector: 'oe-login',
  providers: [HttpClient],
  templateUrl: './oe-login.component.html',
  styleUrls: ['./oe-login.component.scss'],
  imports: [MatButtonModule, MatInputModule, MatIconModule, NgIf, FormsModule],
})
export class OeLoginComponent {
  constructor() {
    this._state.header = undefined;
    window.onkeyup = e => {
      if (e.code === 'Enter') {
        if (this.loginName && this.loginPassword) {
          this.authorize();
        }
      }
    };
  }

  // INJECTS
  private _auth = inject(AuthApiService);
  private _toaster = inject(ToasterService);
  private _router = inject(Router);
  private _state = inject(StateService);

  // VARIABLES
  public loginName = '';
  public loginPassword = '';

  public authorize(): void {
    this._auth
      .authorize({ username: this.loginName, password: this.loginPassword })
      .pipe(
        take(1),
        tap({
          next: response => {
            localStorage.setItem(Api_Token_Key, response.apiKey);
            this._toaster.open(EToastType.Success, 'Úspěšné přihlášení');
            void this._router.navigate([ERoutes.Main, ERoutes.Dashboard]);
          },
          error: () => {
            this._toaster.open(EToastType.Danger, 'Přihlášení bylo neúspěšné.');
          },
        })
      )
      .subscribe();
  }
}
