import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'oe-delete-confirm-dialog',
  providers: [HttpClient],
  templateUrl: './oe-delete-confirm-dialog.component.html',
  styleUrls: ['./oe-delete-confirm-dialog.component.scss'],
  imports: [MatButtonModule, MatIconModule],
})
export class OeDeleteConfirmDialogComponent {
  // INJECTS
  public data = inject(MAT_DIALOG_DATA);
  public dialogRef = inject(MatDialogRef);
}
