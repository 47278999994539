export const CS = {
  dateTime: 'Datum a čas',
  deviceName: 'Název zařízení',
  deviceNameClickable: 'Název zařízení',
  actualPower: 'Aktuální výkon',
  action: 'Akce',
  from: 'Od',
  to: 'Do',
  availability: 'Dostupnost',
  author: 'Autor',
  name: 'Jméno',
  title: 'Název',
  residence: 'Sídlo',
  role: 'Role',
  mail: 'E-mail',
  id: 'ID',
  dic: 'DIČ',
  ic: 'IČ',
  address: 'Adresa',
  phone: 'Telefon',
  electricityDistributor: 'Distributor elektřiny',
  gasDistributor: 'Distributor plynu',
  ean: 'EAN',
  eic: 'EIC',
  gps: 'GPS',
  serviceName: 'Servisní jméno',
  companyName: 'Firma',
  companyNameClickable: 'Firma',
  note: 'Poznámka',
  error: 'Chyba',
  consumptionPoint: 'Odběrné místo',
  type: 'Typ',
};
