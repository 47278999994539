<div style='margin: 30px 15px; min-width: max-content'>
  <div class='table-toolbar'>
    <button mat-stroked-button color="primary" (click)='openGroupHandleDialog()'>
      Přidat skupinu
      <mat-icon class='oe-primary-icon' fontIcon="add" style='margin-right: 6px'></mat-icon>
    </button>
  </div>
  <oe-table
    *ngIf='tableSource'
    [data]='tableSource'
    [pageSize]='10'
    [hiddenColumns]="[]"
    (rowEdit)='openGroupHandleDialog($event)'
    (rowDelete)='openDeleteDialog($event)'
    (detailOpened)='_router.navigate([ERoutes.Main, ERoutes.Group_Management, $event.serviceGroupId])'>
  </oe-table>
</div>
