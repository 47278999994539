<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container [matColumnDef]="column" [ngSwitch]='column' *ngFor='let column of columns'>
    <ng-container *ngSwitchDefault>
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ column | columnHeaderTranslate }} </th>
      <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
    </ng-container>

    <ng-container *ngSwitchCase="'detail'">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style='text-align: end'>
        <button *ngIf='element.detail' (click)='detailOpened.emit(element)' mat-raised-button color="primary">Otevřít</button>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'statusName'">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{element.statusName}}</td>
    </ng-container>statusName

    <ng-container *ngSwitchCase="'index'">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"><div *ngIf='element.index' style='text-align: end'>{{ data.indexOf(element) + 1 }}</div></td>
    </ng-container>

    <ng-container *ngSwitchCase="'status'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element" style='text-align: end'>
        <mat-icon *ngIf="element.status" style='color: #5cb85c' fontIcon="check_circle"></mat-icon>
        <mat-icon *ngIf="!element.status" style='color: #ed4337' fontIcon="cancel"></mat-icon>
      </td>
    </ng-container>


    <ng-container *ngSwitchCase="'boxDeviceStatus'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element" style='text-align: end'>
        <mat-icon *ngIf="element.boxDeviceStatus === EBoxStatus.Connected" style='color: #5cb85c' fontIcon="check_circle"></mat-icon>
        <mat-icon *ngIf="element.boxDeviceStatus === EBoxStatus.Error" style='color: #ed4337' fontIcon="cancel"></mat-icon>
        <mat-icon *ngIf="element.boxDeviceStatus === EBoxStatus.Disconnected" style='color: #F7B217' fontIcon="warning"></mat-icon>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'deviceNameClickable'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Název zařízení</th>
      <td mat-cell *matCellDef="let element" style='text-align: start; width: 70px; padding-left: 0'>
        <button *ngIf='element.deviceNameClickable && element.deviceId' (click)='_router.navigate([ERoutes.Main, ERoutes.Device, element.deviceId])' mat-button color="primary">{{ element.deviceNameClickable }}</button>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'companyNameClickable'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Firma</th>
      <td mat-cell *matCellDef="let element" style='text-align: start; width: 70px; padding-left: 0'>
        <button *ngIf='element.companyNameClickable && element.companyId' (click)='_router.navigate([ERoutes.Main, ERoutes.Company_Management, element.companyId])' mat-button color="primary">{{ element.companyNameClickable }}</button>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'authorClickable'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Autor</th>
      <td mat-cell *matCellDef="let element" style='text-align: start; width: 70px; padding-left: 0'>
        <button *ngIf='element.authorClickable && element.authorId' (click)='_router.navigate([ERoutes.Main, ERoutes.User_Management, element.authorId])' mat-button color="primary">{{ element.authorClickable }}</button>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'edit'">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style='text-align: end; width: 70px; padding: 0'>
        <mat-icon *ngIf='element.edit' class='oe-primary-icon' (click)='rowEdit.emit(element)' fontIcon="edit" style='margin-right: 4px'></mat-icon>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'remove'">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style='text-align: end; width: 70px; padding-left: 0'>
        <mat-icon *ngIf='element.remove' class='oe-primary-icon' (click)='rowDelete.emit(element)' fontIcon="delete" style='margin-right: 4px'></mat-icon>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'userIcon'">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf='element.userIcon' style='position:relative; top: 3px' fontIcon="person"></mat-icon>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'businessIcon'">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf='element.businessIcon' style='position:relative; top: 3px' fontIcon="business"></mat-icon>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'locationIcon'">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf='element.locationIcon' style='position:relative; top: 3px' fontIcon="location_on"></mat-icon>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)='rowSelected.emit(row)'></tr>
</table>

<mat-paginator *ngIf='paging' [pageSizeOptions]="[5, 10, 20]" [pageSize]='pageSize'
               showFirstLastButtons
               aria-label="Select page of periodic elements">
</mat-paginator>
