import { inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EToastType } from '../interfaces/toast.interface';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  // INJECTS
  private _toast = inject(ToastrService);

  public open(type: EToastType, text: string, position = 'bottom-center', title?: string) {
    if (type === EToastType.Success) {
      this._toast.success(text, title, {
        timeOut: 5000,
        positionClass: 'toast-bottom-center',
      });
    } else if (type === EToastType.Danger) {
      this._toast.error(text, title, {
        timeOut: 5000,
        positionClass: 'toast-bottom-center',
      });
    } else if (type === EToastType.Info) {
      this._toast.info(text, title, {
        timeOut: 5000,
        positionClass: 'toast-bottom-center',
      });
    }
  }
}
