<div class='view-wrapper' style='margin: 30px 0' *ngIf='_state.boxService'>
  <div style='margin: 15px'>
    <div class='table-toolbar'>
      <button mat-stroked-button color="primary" style='margin-right: 12px' (click)='openServiceHandleDialog(_state.boxService)'>
        Upravit topnou větev
        <mat-icon class='oe-primary-icon' fontIcon="edit" style='margin-right: 6px'></mat-icon>
      </button>

      <button mat-stroked-button color="primary" (click)='openDeleteDialog()'>
        Smazat topnou větev
        <mat-icon class='oe-primary-icon' fontIcon="delete" style='margin-right: 6px'></mat-icon>
      </button>
    </div>
    <mat-card>
      <div class='m-3'>
        <div class='odd-row'><h5>Status</h5>
          <div style='display: flex' *ngIf='_state.boxService.status === EBoxStatus.Disconnected'>
            <mat-icon style='color: #ed4337; margin-right: 8px' fontIcon="cancel"></mat-icon> Nepřipojeno
          </div>
          <div style='display: flex' *ngIf='_state.boxService.status === EBoxStatus.Connected'>
            <mat-icon style='color: #5cb85c; margin-right: 8px' fontIcon="check_circle"></mat-icon> Připojeno
          </div>
        </div>
        <div class='even-row'><h5>Název</h5>{{_state.boxService.name}}</div>
        <div class='odd-row'><h5>Servisní jméno</h5>{{_state.boxService.serviceName}}</div>
        <div class='even-row'><h5>SPOTBOX</h5>{{_state.boxService.deviceName}}</div>
        <div class='odd-row'><h5>Poznámka</h5>{{_state.boxService.note}}</div>
        <div class='even-row' *ngIf='company'><h5>Firma</h5>{{company.name}}</div>
      </div>
    </mat-card>

    <ng-container *ngIf='settings'>
      <div style='margin-top: 30px'>
        <div class='table-toolbar'>
          <h5 class='card-label' style='margin-bottom: 0; color: white'>Nastavení hodnot řízení topné větve</h5>
        </div>
        <mat-card>
          <div class='m-3'>
            <div style='display: flex;'>
              <mat-form-field style='width: 135px; margin-right: 20px'>
                <mat-label>Konstanta</mat-label>
                <div style='position: absolute; font-size: 15px; right: 50px' *ngIf='settings.constTemp'>°C</div>
                <input [(ngModel)]='settings.constTemp' [type]="'number'" matInput>
              </mat-form-field>
              <mat-form-field style='width: 135px'>
                <mat-label>Mimo dům</mat-label>
                <div style='position: absolute; font-size: 15px; right: 50px' *ngIf='settings.reducedTemp'>°C</div>
                <input [(ngModel)]='settings.reducedTemp' [type]="'number'" matInput>
              </mat-form-field>
            </div>

            <div class='table-toolbar' style='margin-bottom: 30px'>
              <h5 class='card-label' style='margin-bottom: 0; color: white'>Ekvitermní křivka</h5>
            </div>
            <div style='display: flex; flex-wrap: wrap'>
              <div style='margin-right: 15px'>
                <div style='display: flex'>
                  <mat-form-field style='width: 135px; margin-right: 20px'>
                    <mat-label>Venkovní teplota 1</mat-label>
                    <div style='position: absolute; font-size: 15px; right: 50px' *ngIf='settings.equithermalTable.point1.outsideTemp'>°C</div>
                    <input (change)='updateChartModel()' [(ngModel)]='settings.equithermalTable.point1.outsideTemp' [type]="'number'" matInput>
                  </mat-form-field>
                  <mat-form-field style='width: 135px'>
                    <mat-label>Teplota vody 1</mat-label>
                    <div style='position: absolute; font-size: 15px; right: 50px' *ngIf='settings.equithermalTable.point1.waterTemp'>°C</div>
                    <input (change)='updateChartModel()' [(ngModel)]='settings.equithermalTable.point1.waterTemp' [type]="'number'" matInput>
                  </mat-form-field>
                </div>

                <div style='display: flex'>
                  <mat-form-field style='width: 135px; margin-right: 20px'>
                    <mat-label>Venkovní teplota 2</mat-label>
                    <div style='position: absolute; font-size: 15px; right: 50px' *ngIf='settings.equithermalTable.point2.outsideTemp'>°C</div>
                    <input (change)='updateChartModel()' [(ngModel)]='settings.equithermalTable.point2.outsideTemp' [type]="'number'" matInput>
                  </mat-form-field>
                  <mat-form-field style='width: 135px'>
                    <mat-label>Teplota vody 2</mat-label>
                    <div style='position: absolute; font-size: 15px; right: 50px' *ngIf='settings.equithermalTable.point2.waterTemp'>°C</div>
                    <input (change)='updateChartModel()' [(ngModel)]='settings.equithermalTable.point2.waterTemp' [type]="'number'" matInput>
                  </mat-form-field>
                </div>

                <div style='display: flex'>
                  <mat-form-field style='width: 135px; margin-right: 20px'>
                    <mat-label>Venkovní teplota 3</mat-label>
                    <div style='position: absolute; font-size: 15px; right: 50px' *ngIf='settings.equithermalTable.point3.outsideTemp'>°C</div>
                    <input (change)='updateChartModel()' [(ngModel)]='settings.equithermalTable.point3.outsideTemp' [type]="'number'" matInput>
                  </mat-form-field>
                  <mat-form-field style='width: 135px'>
                    <mat-label>Teplota vody 3</mat-label>
                    <div style='position: absolute; font-size: 15px; right: 50px' *ngIf='settings.equithermalTable.point3.waterTemp'>°C</div>
                    <input (change)='updateChartModel()' [(ngModel)]='settings.equithermalTable.point3.waterTemp' [type]="'number'" matInput>
                  </mat-form-field>
                </div>

                <div style='display: flex'>
                  <mat-form-field style='width: 135px; margin-right: 20px'>
                    <mat-label>Venkovní teplota 4</mat-label>
                    <div style='position: absolute; font-size: 15px; right: 50px' *ngIf='settings.equithermalTable.point4.outsideTemp'>°C</div>
                    <input (change)='updateChartModel()' [(ngModel)]='settings.equithermalTable.point4.outsideTemp' [type]="'number'" matInput>
                  </mat-form-field>
                  <mat-form-field style='width: 135px'>
                    <mat-label>Teplota vody 4</mat-label>
                    <div style='position: absolute; font-size: 15px; right: 50px' *ngIf='settings.equithermalTable.point4.waterTemp'>°C</div>
                    <input (change)='updateChartModel()' [(ngModel)]='settings.equithermalTable.point4.waterTemp' [type]="'number'" matInput>
                  </mat-form-field>
                </div>
              </div>

              <div class='chart-wrapper'>
                <ngx-charts-line-chart
                  [xAxis]="true"
                  [yAxis]="true"
                  [yAxisTickFormatting]='_material.formatHeat'
                  [xAxisTickFormatting]='_material.formatHeat'
                  [scheme]="_state.chartColorScheme"
                  [results]="chartSource"
                  [yAxisTicks]='[0, 5 ,10 ,15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]'
                  [curve]='curve'
                  [gradient]="true">
                </ngx-charts-line-chart>
              </div>
            </div>

            <div style='display: flex; justify-content: center; margin-top: 15px'>
              <button
                (click)="cancelUpdates('values')"
                style='margin-right: 10px'
                mat-button color="primary">
                Zrušit
              </button>
              <button
                (click)="applySettings('values')"
                style='margin-left: 10px'
                mat-raised-button color="primary">
                Upravit
              </button>
            </div>
          </div>
        </mat-card>
      </div>

      <div style='margin-top: 30px'>
        <div class='table-toolbar' style='justify-content: space-between'>
          <h5 class='card-label' style='margin-bottom: 0; color: white'>Časové nastavení řízení topné větve</h5>

          <div>
            <button mat-stroked-button color="primary" (click)='copyDailySettings()' style='margin-right: 12px'>
              Kopírovat
              <mat-icon class='oe-primary-icon' fontIcon="content_copy" style='margin-right: 6px'></mat-icon>
            </button>

            <button mat-stroked-button color="primary" (click)='pasteDailySettings()'>
              Vložit
              <mat-icon class='oe-primary-icon' fontIcon="content_paste_go" style='margin-right: 6px'></mat-icon>
            </button>
          </div>
        </div>
        <mat-card>
          <div class='m-3'>
            <mat-tab-group [(selectedIndex)]='selectedDay'>
              <mat-tab [label]="'Pondělí'"><oe-heating-branch-daily (settingsChanged)="registerNewTimeSettings($event, 'monday')" [settings]='settings.timeControl.monday'></oe-heating-branch-daily></mat-tab>
              <mat-tab [label]="'Úterý'"><oe-heating-branch-daily (settingsChanged)="registerNewTimeSettings($event, 'tuesday')" [settings]='settings.timeControl.tuesday'></oe-heating-branch-daily></mat-tab>
              <mat-tab [label]="'Středa'"><oe-heating-branch-daily (settingsChanged)="registerNewTimeSettings($event, 'wednesday')" [settings]='settings.timeControl.wednesday'></oe-heating-branch-daily></mat-tab>
              <mat-tab [label]="'Čtvrtek'"><oe-heating-branch-daily (settingsChanged)="registerNewTimeSettings($event, 'thursday')" [settings]='settings.timeControl.thursday'></oe-heating-branch-daily></mat-tab>
              <mat-tab [label]="'Pátek'"><oe-heating-branch-daily (settingsChanged)="registerNewTimeSettings($event, 'friday')" [settings]='settings.timeControl.friday'></oe-heating-branch-daily></mat-tab>
              <mat-tab [label]="'Sobota'"><oe-heating-branch-daily (settingsChanged)="registerNewTimeSettings($event, 'saturday')" [settings]='settings.timeControl.saturday'></oe-heating-branch-daily></mat-tab>
              <mat-tab [label]="'Neděle'"><oe-heating-branch-daily (settingsChanged)="registerNewTimeSettings($event, 'sunday')" [settings]='settings.timeControl.sunday'></oe-heating-branch-daily></mat-tab>
            </mat-tab-group>

            <div style='display: flex; justify-content: center'>
              <button
                (click)="cancelUpdates('time')"
                style='margin-right: 10px'
                mat-button color="primary">
                Zrušit
              </button>
              <button
                (click)="applySettings('time')"
                style='margin-left: 10px'
                mat-raised-button color="primary">
                Upravit
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
