<div style='position: relative'>
  <mat-icon class='oe-close-icon' style='position: absolute; top: 10px; right: 10px' (click)='dialogRef.close()' fontIcon="close"></mat-icon>

  <div style='position: relative; top: 50px'>
    <h3 style='text-align: center; margin-bottom: 25px'>{{ this.data ? 'Upravit odběrné místo' : 'Přidat odběrné místo' }}</h3>

    <div style='display: flex; flex-wrap: wrap; margin: 0 30px; justify-content: space-between'>
      <mat-form-field style='width: 350px'>
        <mat-label>EAN</mat-label>
        <input [(ngModel)]='consumptionPoint.ean' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>EIC</mat-label>
        <input [(ngModel)]='consumptionPoint.eic' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>Adresa</mat-label>
        <input [(ngModel)]='consumptionPoint.address' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>GPS</mat-label>
        <input [(ngModel)]='consumptionPoint.gps' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>Distributor elektřiny</mat-label>
        <mat-select [(value)]="consumptionPoint.electricityDistributor">
          <mat-option [value]="distributor.key" *ngFor='let distributor of elDistributors'>{{ distributor.value }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>Distributor plynu</mat-label>
        <mat-select [(value)]="consumptionPoint.gasDistributor">
          <mat-option [value]="distributor.key" *ngFor='let distributor of gasDistributors'>{{ distributor.value }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>Firma</mat-label>
        <mat-select [(value)]="consumptionPoint.companyId">
          <mat-option [value]="company.companyId" *ngFor='let company of companyList'>{{ company.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style='display: flex; justify-content: center; margin-top: 15px'>
      <button
        (click)='dialogRef.close()'
        style='margin-right: 10px'
        mat-button color="primary">
        Zrušit
      </button>
      <button
        (click)='dialogRef.close(consumptionPoint)'
        style='margin-left: 10px'
        mat-raised-button color="primary">
        {{ this.data ? 'Upravit' : 'Přidat' }}
      </button>
    </div>
  </div>
</div>
