import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { ICompany } from '../../interfaces/company.interface';
import { EToastType } from '../../interfaces/toast.interface';
import { CompanyApiService } from '../../services/company-api.service';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';
import { OeCompanyHandleDialogComponent } from '../oe-company-handle-dialog/oe-company-handle-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeTableComponent } from '../oe-table/oe-table.component';

@Component({
  standalone: true,
  selector: 'oe-company-management',
  providers: [HttpClient],
  templateUrl: './oe-company-management.component.html',
  styleUrls: ['./oe-company-management.component.scss'],
  imports: [MatButton, OeTableComponent, NgIf, MatIcon],
})
export class OeCompanyManagementComponent implements OnInit {
  // INJECTS
  private _company = inject(CompanyApiService);
  private _dialog = inject(MatDialog);
  private _toast = inject(ToasterService);
  public _router = inject(Router);
  public _state = inject(StateService);

  // VARIABLES
  public tableSource!: any;
  public company!: ICompany;

  // ENUMS
  protected readonly ERoutes = ERoutes;

  ngOnInit() {
    this.setupTable();
  }

  public openDeleteDialog(company: ICompany) {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this._company
            .deleteCompany(company?.companyId as string)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this._toast.open(EToastType.Success, 'Firma byla úspěšně odstraněna.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Firmu nebylo možné odstranit.');
              },
            });
        }
      });
  }

  public openCompanyHandleDialog(company?: ICompany) {
    if (company) {
      this._company
        .getCompanyList()
        .pipe(take(1))
        .subscribe(companies => {
          this.company = companies.list.find(company => company.companyId === company?.companyId) as ICompany;

          this._dialog
            .open(OeCompanyHandleDialogComponent, {
              height: '420px',
              width: '780px',
              data: this.company,
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this._company
                  .editCompany({ ...result, companyId: this.company.companyId })
                  .pipe(take(1))
                  .subscribe({
                    next: () => {
                      this._toast.open(EToastType.Success, 'Firma byla úspěšně upravena.');
                      this.setupTable();
                    },
                    error: err => {
                      this._toast.open(EToastType.Danger, err.error.message || 'Firmu nebylo možné upravit.');
                    },
                  });
              }
            });
        });
    } else {
      this._dialog
        .open(OeCompanyHandleDialogComponent, {
          height: '420px',
          width: '780px',
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this._company
              .uploadCompany(result)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this._toast.open(EToastType.Success, 'Firma byla úspěšně vytvořena.');
                  this.setupTable();
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Firmu nebylo možné vytvořit.');
                },
              });
          }
        });
    }
  }

  private setupTable() {
    this.tableSource = null;

    this._company
      .getCompanyList()
      .pipe(take(1))
      .subscribe(response => {
        this.tableSource = response.list.map(company => {
          return {
            businessIcon: true,
            title: company.name,
            dic: company.dic,
            ic: company.ic,
            residence: company.address,
            mail: company.email,
            phone: company.phone,
            detail: true,
            edit: true,
            remove: true,
            companyId: company.companyId,
          };
        });
      });
  }
}
