import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { ERoutes } from '../../enums/oe-routes.enum';
import { EToolbarHeader } from '../../interfaces/state.interface';
import { AuthApiService } from '../../services/auth-api.service';
import { StateService } from '../../services/state.service';

@Component({
  standalone: true,
  selector: 'oe-dashboard',
  providers: [HttpClient],
  templateUrl: './oe-dashboard.component.html',
  styleUrls: ['./oe-dashboard.component.scss'],
  imports: [MatCardModule],
})
export class OeDashboardComponent {
  // INJECTS
  public _state = inject(StateService);
  public _router = inject(Router);

  // ENUMS
  protected readonly ERoutes = ERoutes;

  constructor() {
    this._state.header = EToolbarHeader.Dashboard;
  }
}
