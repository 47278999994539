import { NgForOf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

@Component({
  standalone: true,
  selector: 'oe-change-password-dialog',
  providers: [HttpClient],
  templateUrl: './oe-change-password-dialog.component.html',
  styleUrls: ['./oe-change-password-dialog.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
  ],
})
export class OeChangePasswordDialogComponent {
  // INJECTS
  public data = inject(MAT_DIALOG_DATA);
  public dialogRef = inject(MatDialogRef);

  // VARIABLES
  public user = {
    old: '',
    new: '',
    newConfirm: '',
  };
}
