<div style='position: relative'>
  <mat-icon class='oe-close-icon' style='position: absolute; top: 10px; right: 10px' (click)='dialogRef.close()' fontIcon="close"></mat-icon>

  <div style='position: relative; top: 50px'>
    <h3 style='text-align: center; margin-bottom: 25px'>{{ data.name ? 'Upravit službu' : 'Přidat službu' }}</h3>

    <div style='display: flex; flex-wrap: wrap; margin: 0 30px; justify-content: space-between'>
      <mat-form-field style='width: 350px'>
        <mat-label>Jméno</mat-label>
        <input [(ngModel)]='box.name' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>Typ</mat-label>
        <mat-select (valueChange)='setServiceNamePreset($event)' [(value)]="box.type">
          <mat-option [value]="EBoxServiceType.HeatingBranch">{{ EBoxServiceTypeName.HeatingBranch }}</mat-option>
          <mat-option [value]="EBoxServiceType.PumpHeatingBranch">{{ EBoxServiceTypeName.PumpHeatingBranch }}</mat-option>
          <mat-option [value]="EBoxServiceType.HotWaterTank">{{ EBoxServiceTypeName.HotWaterTank }}</mat-option>
          <mat-option [value]="EBoxServiceType.PowerMeter">{{ EBoxServiceTypeName.PowerMeter }}</mat-option>
          <mat-option [value]="EBoxServiceType.AssociatedPowerMeter">{{ EBoxServiceTypeName.AssociatedPowerMeter }}</mat-option>
          <mat-option [value]="EBoxServiceType.WaterMeterHot">{{ EBoxServiceTypeName.WaterMeterHot }}</mat-option>
          <mat-option [value]="EBoxServiceType.WaterMeterCold">{{ EBoxServiceTypeName.WaterMeterCold }}</mat-option>
          <mat-option [value]="EBoxServiceType.Calorimeter">{{ EBoxServiceTypeName.Calorimeter }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>Servisní jméno</mat-label>
        <input [(ngModel)]='box.serviceName' [type]="'text'" matInput>
      </mat-form-field>

<!--      <mat-form-field style='width: 350px'>-->
<!--        <mat-label>Firma</mat-label>-->
<!--        <mat-select [(value)]="box.companyId">-->
<!--          <mat-option [value]="company.companyId" *ngFor='let company of companyList'>{{ company.name }}</mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->

<!--      <mat-form-field style='width: 350px' *ngIf='!data.name'>-->
<!--        <mat-label>SPOTBOX</mat-label>-->
<!--        <mat-select [(value)]="box.deviceId">-->
<!--          <mat-option [value]="device.deviceId" *ngFor='let device of deviceList'>{{ device.name }}</mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->

      <mat-form-field style='width: 350px'>
        <mat-label>Poznámka</mat-label>
        <input [(ngModel)]='box.note' [type]="'text'" matInput>
      </mat-form-field>
    </div>

    <div style='display: flex; justify-content: center; margin-top: 15px'>
      <button
        (click)='dialogRef.close()'
        style='margin-right: 10px'
        mat-button color="primary">
        Zrušit
      </button>
      <button
        (click)='dialogRef.close(box)'
        style='margin-left: 10px'
        mat-raised-button color="primary">
        {{ data.name ? 'Upravit' : 'Přidat' }}
      </button>
    </div>
  </div>
</div>
