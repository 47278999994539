import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatCell } from '@angular/material/table';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { LineChartModule } from '@swimlane/ngx-charts';
import * as shape from 'd3-shape';
import { forkJoin, take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { EBoxStatus, IBoxDevice, IBoxService } from '../../interfaces/box-service.interface';
import { ICompany } from '../../interfaces/company.interface';
import {
  EHeatingBranchEquithermType,
  IHeatingBranchDailySettings,
  IHeatingBranchSettings,
} from '../../interfaces/heating-branch.interface';
import { EToastMessage, EToastType } from '../../interfaces/toast.interface';
import { BoxDeviceApiService } from '../../services/box-device-api.service';
import { BoxServiceApiService } from '../../services/box-service-api.service';
import { CompanyApiService } from '../../services/company-api.service';
import { HeatingBranchApiService } from '../../services/heating-branch-api.service';
import { MaterialService } from '../../services/material.service';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';
import { OeBoxServiceHandleDialogComponent } from '../oe-box-service-handle-dialog/oe-box-service-handle-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeHeatingBranchDailyComponent } from '../oe-heating-branch-daily/oe-heating-branch-daily.component';
import { OeTableComponent } from '../oe-table/oe-table.component';

@Component({
  standalone: true,
  selector: 'oe-heating-branch-detail',
  providers: [HttpClient],
  templateUrl: './oe-heating-branch-detail.component.html',
  styleUrls: ['./oe-heating-branch-detail.component.scss'],
  imports: [
    MatCardModule,
    NgIf,
    MatButton,
    MatCell,
    MatIcon,
    OeTableComponent,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    FormsModule,
    MatTabGroup,
    MatTab,
    OeHeatingBranchDailyComponent,
    LineChartModule,
  ],
})
export class OeHeatingBranchDetailComponent implements OnInit {
  // INJECTS
  public _state = inject(StateService);
  public _material = inject(MaterialService);
  private _router = inject(Router);
  private _dialog = inject(MatDialog);
  private _toast = inject(ToasterService);
  private _boxService = inject(BoxServiceApiService);
  private _boxDevice = inject(BoxDeviceApiService);
  private _company = inject(CompanyApiService);
  private _heatingBranch = inject(HeatingBranchApiService);

  // VARIABLES
  private heatingBranchId!: string;
  private boxDevices: IBoxDevice[] = [];
  public company!: ICompany;
  public settings!: IHeatingBranchSettings;
  public chartSource!: any;
  public curve = shape.curveCardinal;
  public selectedDay = 0;

  // ENUMS
  protected readonly EBoxStatus = EBoxStatus;

  ngOnInit() {
    const routes = this._router.url.split('/');
    this.heatingBranchId = routes[routes.indexOf(ERoutes.Heating_Branch_Management) + 1];

    this._heatingBranch
      .getBranchSettings(this.heatingBranchId)
      .pipe(take(1))
      .subscribe(res => {
        this.settings = res;
        this._state.oldHeatingBranchSetting$.next(JSON.parse(JSON.stringify(res)));
        this.updateChartModel();
      });

    this._company
      .getCompanyList()
      .pipe(take(1))
      .subscribe(res => {
        const companies = res.list;
        const requests = res.list.map(company => {
          return this._boxDevice.getBoxDeviceList(company.companyId as string);
        });

        forkJoin(requests)
          .pipe(take(1))
          .subscribe(res => {
            res.forEach(r => {
              r.devices.forEach(device => {
                this.boxDevices.push(device);
              });
            });

            this._boxService
              .getBoxService(this.heatingBranchId)
              .pipe(take(1))
              .subscribe(service => {
                this._state.boxService = {
                  ...service,
                  deviceName: this.boxDevices.find(s => s.deviceId === service.deviceId)?.name,
                };

                this.company = companies.find(c => c.companyId === service.companyId) as ICompany;
              });
          });
      });
  }

  public pasteDailySettings() {
    switch (this.selectedDay) {
      case 0:
        this._state.copiedHeatingBranchDailySettings$.pipe(take(1)).subscribe(settings => {
          this.settings.timeControl.monday = settings as IHeatingBranchDailySettings;
        });
        break;
      case 1:
        this._state.copiedHeatingBranchDailySettings$.pipe(take(1)).subscribe(settings => {
          this.settings.timeControl.tuesday = settings as IHeatingBranchDailySettings;
        });
        break;
      case 2:
        this._state.copiedHeatingBranchDailySettings$.pipe(take(1)).subscribe(settings => {
          this.settings.timeControl.wednesday = settings as IHeatingBranchDailySettings;
        });
        break;
      case 3:
        this._state.copiedHeatingBranchDailySettings$.pipe(take(1)).subscribe(settings => {
          this.settings.timeControl.thursday = settings as IHeatingBranchDailySettings;
        });
        break;
      case 4:
        this._state.copiedHeatingBranchDailySettings$.pipe(take(1)).subscribe(settings => {
          this.settings.timeControl.friday = settings as IHeatingBranchDailySettings;
        });
        break;
      case 5:
        this._state.copiedHeatingBranchDailySettings$.pipe(take(1)).subscribe(settings => {
          this.settings.timeControl.saturday = settings as IHeatingBranchDailySettings;
        });
        break;
      case 6:
        this._state.copiedHeatingBranchDailySettings$.pipe(take(1)).subscribe(settings => {
          this.settings.timeControl.sunday = settings as IHeatingBranchDailySettings;
        });
        break;
    }

    this._toast.open(EToastType.Info, 'Vloženo.');
  }

  public copyDailySettings() {
    switch (this.selectedDay) {
      case 0:
        this._state.copiedHeatingBranchDailySettings$.next(
          JSON.parse(JSON.stringify(this.settings.timeControl.monday))
        );
        break;
      case 1:
        this._state.copiedHeatingBranchDailySettings$.next(
          JSON.parse(JSON.stringify(this.settings.timeControl.tuesday))
        );
        break;
      case 2:
        this._state.copiedHeatingBranchDailySettings$.next(
          JSON.parse(JSON.stringify(this.settings.timeControl.wednesday))
        );
        break;
      case 3:
        this._state.copiedHeatingBranchDailySettings$.next(
          JSON.parse(JSON.stringify(this.settings.timeControl.thursday))
        );
        break;
      case 4:
        this._state.copiedHeatingBranchDailySettings$.next(
          JSON.parse(JSON.stringify(this.settings.timeControl.friday))
        );
        break;
      case 5:
        this._state.copiedHeatingBranchDailySettings$.next(
          JSON.parse(JSON.stringify(this.settings.timeControl.saturday))
        );
        break;
      case 6:
        this._state.copiedHeatingBranchDailySettings$.next(
          JSON.parse(JSON.stringify(this.settings.timeControl.sunday))
        );
        break;
    }

    this._toast.open(EToastType.Info, 'Zkopírováno nastavení dne.');
  }

  public applySettings(type: 'time' | 'values') {
    const oldSettings = this._state.oldHeatingBranchSetting$.getValue() as IHeatingBranchSettings;
    if (type === 'time') {
      const newSettings: IHeatingBranchSettings = { ...oldSettings, timeControl: this.settings.timeControl };
      this._heatingBranch
        .updateBranchSettings(this.heatingBranchId, newSettings)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this._state.oldHeatingBranchSetting$.next(JSON.parse(JSON.stringify(newSettings)));
            this._toast.open(EToastType.Success, EToastMessage.Heating_Branch_Time_Settings_Update_Success);
          },
          error: err => {
            this._toast.open(
              EToastType.Danger,
              err.error.message || EToastMessage.Heating_Branch_Time_Settings_Update_Failure
            );
          },
        });
    } else {
      const newSettings: IHeatingBranchSettings = {
        ...oldSettings,
        constTemp: this.settings.constTemp,
        reducedTemp: this.settings.reducedTemp,
        equithermalTable: this.settings.equithermalTable,
      };
      this._heatingBranch
        .updateBranchSettings(this.heatingBranchId, newSettings)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this._state.oldHeatingBranchSetting$.next(JSON.parse(JSON.stringify(newSettings)));
            this._toast.open(EToastType.Success, EToastMessage.Heating_Branch_Values_Settings_Update_Success);
          },
          error: err => {
            this._toast.open(
              EToastType.Danger,
              err.error.message || EToastMessage.Heating_Branch_Values_Settings_Update_Failure
            );
          },
        });
    }
  }

  public registerNewTimeSettings(
    settings: { label: string; checked: boolean; value: EHeatingBranchEquithermType }[],
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'
  ) {
    this.settings.timeControl[day].hourSettings = settings.map(s => s.value);
  }

  public updateChartModel() {
    this.chartSource = null;
    this.chartSource = [
      {
        name: 'Ekvitermní křivka',
        series: [
          {
            name: this.settings.equithermalTable.point1.outsideTemp.toString(),
            value: Number(this.settings.equithermalTable.point1.waterTemp),
          },
          {
            name: this.settings.equithermalTable.point2.outsideTemp.toString(),
            value: Number(this.settings.equithermalTable.point2.waterTemp),
          },
          {
            name: this.settings.equithermalTable.point3.outsideTemp.toString(),
            value: Number(this.settings.equithermalTable.point3.waterTemp),
          },
          {
            name: this.settings.equithermalTable.point4.outsideTemp.toString(),
            value: Number(this.settings.equithermalTable.point4.waterTemp),
          },
        ],
      },
    ];
  }

  public cancelUpdates(type: 'time' | 'values') {
    const oldSettings = this._state.oldHeatingBranchSetting$.getValue() as IHeatingBranchSettings;
    if (type === 'time') {
      this.settings = { ...this.settings, timeControl: oldSettings.timeControl };
    } else {
      this.settings = {
        ...this.settings,
        constTemp: oldSettings.constTemp,
        reducedTemp: oldSettings.reducedTemp,
        equithermalTable: oldSettings.equithermalTable,
      };
      this.updateChartModel();
    }
  }

  public openDeleteDialog() {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        this._boxService
          .deleteBoxService(this._state.boxService?.serviceId as string)
          .pipe(take(1))
          .subscribe({
            next: () => {
              void this._router.navigate([ERoutes.Main, ERoutes.Heating_Branch_Management]);
              this._toast.open(EToastType.Success, 'Služba byla úspěšně smazána.');
            },
            error: err => {
              this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné smazat.');
            },
          });
      });
  }

  public openServiceHandleDialog(box: IBoxService) {
    this._dialog
      .open(OeBoxServiceHandleDialogComponent, {
        height: '420px',
        width: '780px',
        data: box,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._boxService
            .editBoxService(result)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this._boxService
                  .getBoxService(this.heatingBranchId)
                  .pipe(take(1))
                  .subscribe(service => {
                    this._state.boxService = {
                      ...service,
                      deviceName: this.boxDevices.find(s => s.deviceId === service.deviceId)?.name,
                    };
                  });
                this._toast.open(EToastType.Success, 'Služba byla úspěšně upravena.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné upravit.');
              },
            });
        }
      });
  }
}
