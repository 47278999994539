<div style='position: relative'>
  <mat-icon class='oe-close-icon' style='position: absolute; top: 10px; right: 10px' (click)='dialogRef.close()' fontIcon="close"></mat-icon>

  <div style='position: relative; top: 50px'>
    <h3 style='text-align: center; margin-bottom: 25px'>{{ this.data ? 'Upravit spotbox' : 'Přidat spotbox' }}</h3>

    <div style='display: flex; flex-wrap: wrap; margin: 0 30px; justify-content: space-between'>
      <mat-form-field style='width: 350px'>
        <mat-label>Název</mat-label>
        <input [(ngModel)]='spotbox.name' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>Odběrné místo</mat-label>
        <input [(ngModel)]='spotbox.consumptionPoint' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 350px'>
        <mat-label>Poznámka</mat-label>
        <input [(ngModel)]='spotbox.note' [type]="'text'" matInput>
      </mat-form-field>
    </div>

    <div style='display: flex; justify-content: center; margin-top: 15px'>
      <button
        (click)='dialogRef.close()'
        style='margin-right: 10px'
        mat-button color="primary">
        Zrušit
      </button>
      <button
        (click)='dialogRef.close(spotbox)'
        style='margin-left: 10px'
        mat-raised-button color="primary">
        {{ this.data ? 'Upravit' : 'Přidat' }}
      </button>
    </div>
  </div>
</div>
