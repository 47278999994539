import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { STANDARD_OE_API_URL } from '../constants/standard-oe-api.constant';
import { IBoxService } from '../interfaces/box-service.interface';
import { IListResponse, IServiceResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class BoxServiceApiService {
  // INJECTS
  private _http = inject(HttpClient);

  public getBoxServiceList(deviceId?: string, companyId?: string) {
    return this._http.post<IServiceResponse<IBoxService>>(`${STANDARD_OE_API_URL}/box/service/get-services`, {
      deviceId: deviceId,
      companyId: companyId,
    });
  }

  public createBoxService(body: IBoxService) {
    return this._http.post<IListResponse<IBoxService>>(`${STANDARD_OE_API_URL}/box/service/create-service`, body);
  }

  public editBoxService(body: IBoxService) {
    return this._http.post<IBoxService>(`${STANDARD_OE_API_URL}/box/service/edit-service`, body);
  }

  public deleteBoxService(serviceId: string) {
    return this._http.post<IServiceResponse<IBoxService>>(`${STANDARD_OE_API_URL}/box/service/delete-service`, {
      serviceId: serviceId,
    });
  }

  public getBoxService(serviceId: string) {
    return this._http.post<IBoxService>(`${STANDARD_OE_API_URL}/box/service/get-service`, {
      serviceId: serviceId,
    });
  }

  public getServicesByGroup(serviceGroupId: string) {
    return this._http.post<IServiceResponse<IBoxService>>(
      `${STANDARD_OE_API_URL}/box/service/get-services-by-service-group`,
      {
        serviceGroupId: serviceGroupId,
      }
    );
  }
}
