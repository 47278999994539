import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { STANDARD_OE_API_URL } from '../constants/standard-oe-api.constant';
import { IListResponse } from '../interfaces/response.interface';
import { IUser } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  // INJECTS
  private _http = inject(HttpClient);

  public uploadUser(body: IUser) {
    return this._http.post<IUser>(`${STANDARD_OE_API_URL}/user/add-user`, body);
  }

  public editUser(body: IUser) {
    return this._http.post<IUser>(`${STANDARD_OE_API_URL}/user/edit-user`, body);
  }

  public deleteUser(userId: string) {
    return this._http.post(`${STANDARD_OE_API_URL}/user/delete-user`, { userId: userId });
  }

  public getUserList() {
    return this._http.post<IListResponse<IUser>>(`${STANDARD_OE_API_URL}/user/get-users`, {});
  }

  public changePassword(oldPassword: string, newPassword: string) {
    return this._http.post(`${STANDARD_OE_API_URL}/user/change-password`, {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }
}
