import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api_Token_Key } from '../app/shared/interfaces/auth.interface';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    req = req.clone({
      setHeaders: {
        'X-Api-Key': `${localStorage.getItem(Api_Token_Key)}`,
      },
    });

    return next.handle(req);
  }
}
