import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatCell } from '@angular/material/table';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { EBoxStatus, IBoxDevice, IBoxService, serviceTypeTranslation } from '../../interfaces/box-service.interface';
import { EToastMessage, EToastType } from '../../interfaces/toast.interface';
import { BoxDeviceApiService } from '../../services/box-device-api.service';
import { BoxServiceApiService } from '../../services/box-service-api.service';
import { CompanyApiService } from '../../services/company-api.service';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';
import { OeBoxServiceHandleDialogComponent } from '../oe-box-service-handle-dialog/oe-box-service-handle-dialog.component';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeSpotboxHandleDialogComponent } from '../oe-spotbox-handle-dialog/oe-spotbox-handle-dialog.component';
import { OeTableComponent } from '../oe-table/oe-table.component';

@Component({
  standalone: true,
  selector: 'oe-spot-box-detail',
  providers: [HttpClient],
  templateUrl: './oe-spot-box-detail.component.html',
  styleUrls: ['./oe-spot-box-detail.component.scss'],
  imports: [MatButton, MatCard, MatIcon, NgIf, MatCell, OeTableComponent],
})
export class OeSpotBoxDetailComponent implements OnInit {
  // INJECTS
  private _boxService = inject(BoxServiceApiService);
  private _company = inject(CompanyApiService);
  private _boxDevice = inject(BoxDeviceApiService);
  private _dialog = inject(MatDialog);
  private _toast = inject(ToasterService);
  public _state = inject(StateService);
  public _router = inject(Router);

  // ENUMS
  protected readonly ERoutes = ERoutes;
  protected readonly EBoxStatus = EBoxStatus;
  protected readonly dayjs = dayjs;

  // VARIABLES
  private boxDeviceId!: string;
  public tableSource: any;

  ngOnInit() {
    const routes = this._router.url.split('/');
    const companyId = routes[routes.indexOf(ERoutes.Company_Management) + 1];
    this.boxDeviceId = routes[routes.indexOf(ERoutes.Spotbox_Management) + 1];

    this._company
      .getCompany(companyId)
      .pipe(take(1))
      .subscribe(company => {
        this._state.company = company;
      });

    this.setBoxDevice();
    this.setupServicesTable();
  }

  private setupServicesTable() {
    this.tableSource = null;

    this._boxService
      .getBoxServiceList(this.boxDeviceId)
      .pipe(take(1))
      .subscribe(res => {
        const boxServiceList: IBoxService[] = res.services;

        this.tableSource = boxServiceList.map(service => {
          return {
            status: service.status === 'connected',
            id: service.serviceId,
            deviceId: service.deviceId,
            // deviceNameClickable: devices.find(d => d.deviceId === service.deviceId)?.name,
            // deviceName: this._state.boxDevice?.name,
            name: service.name,
            serviceName: service.serviceName,
            type: serviceTypeTranslation[service.type],
            note: service.note,
            // companyNameClickable: this._state.company?.name,
            companyId: service.companyId,
            detail: true,
            edit: true,
            remove: true,
          };
        });
      });
  }

  private setBoxDevice() {
    this._boxDevice
      .getBoxDevice(this.boxDeviceId)
      .pipe(take(1))
      .subscribe(device => {
        this._state.boxDevice = device;
      });
  }

  public testBoxConnection(e: IBoxDevice) {
    this._boxDevice
      .testBoxDevice(e.deviceId)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this._toast.open(EToastType.Success, 'Spotbox je úspěšně připojen.');
        },
        error: err => {
          this._toast.open(EToastType.Danger, err.error.message || 'Spotbox není úspěšně připojen.');
        },
      });
  }

  public openDeleteDialog(e: IBoxDevice) {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._boxDevice
            .deleteBoxDevice(e.deviceId)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this._toast.open(EToastType.Success, 'Spotbox byl úspěšně odstraněn.');
                void this._router.navigate([ERoutes.Main, ERoutes.Company_Management, this._state.company?.companyId]);
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Spotbox nebylo možné odstranit.');
              },
            });
        }
      });
  }

  public openHandleDialog(e: IBoxDevice) {
    this._dialog
      .open(OeSpotboxHandleDialogComponent, {
        height: '335px',
        width: '780px',
        data: e,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._boxDevice
            .editBoxDevice(result)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this._toast.open(EToastType.Success, 'Spotbox byl úspěšně upraven.');
                this.setBoxDevice();
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Spotbox nebylo možné upravit.');
              },
            });
        }
      });
  }

  public openServiceDeleteDialog(e: IBoxService) {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._boxService
            .deleteBoxService(e?.id as string)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.setupServicesTable();
                this._toast.open(EToastType.Success, 'Služba byla úspěšně smazána.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné smazat.');
              },
            });
        }
      });
  }

  public openServiceHandleDialog(e?: IBoxService) {
    this._dialog
      .open(OeBoxServiceHandleDialogComponent, {
        height: '420px',
        width: '780px',
        data: { ...e, deviceId: this.boxDeviceId, companyId: this._state.company?.companyId },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (!e) {
            this._boxService
              .createBoxService(result)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setupServicesTable();
                  this._toast.open(EToastType.Success, EToastMessage.BoxService_Create_Success);
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné vytvořit.');
                },
              });
          } else {
            this._boxService
              .editBoxService({ ...result, serviceId: e.id })
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setupServicesTable();
                  this._toast.open(EToastType.Success, 'Služba byla úspěšně upravena.');
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Službu nebylo možné upravit.');
                },
              });
          }
        }
      });
  }
}
