export interface IHeatingBranchData {
  serviceId: string;
  lastDateTime: string | Date;
  type: string;
  serviceDataDetail: IHeatingBranchDataDetail | null;
  freshData: boolean;
  name: string;
}

export interface IHeatingBranchDataDetail {
  tempOut: number;
  tempId: number;
  tempOutdoor: number;
  tempRequired: number;
  circulationPump: EHeatingBranchCirculationPump;
  regValve: number;
  status: EHeatingBranchStatus;
}

export interface IHeatingBranchSettings {
  constTemp: number;
  minTemp: number;
  reducedTemp: number;
  controlType: number;
  equithermalTable: {
    point1: IHeatingBranchSettingsEquithermalPoint;
    point2: IHeatingBranchSettingsEquithermalPoint;
    point3: IHeatingBranchSettingsEquithermalPoint;
    point4: IHeatingBranchSettingsEquithermalPoint;
  };
  timeControl: {
    monday: IHeatingBranchDailySettings;
    tuesday: IHeatingBranchDailySettings;
    wednesday: IHeatingBranchDailySettings;
    thursday: IHeatingBranchDailySettings;
    friday: IHeatingBranchDailySettings;
    saturday: IHeatingBranchDailySettings;
    sunday: IHeatingBranchDailySettings;
  };
}

export interface IHeatingBranchDailySettings {
  hourSettings: EHeatingBranchEquithermType[];
}

export interface IHeatingBranchSettingsEquithermalPoint {
  outsideTemp: number;
  waterTemp: number;
}

export enum EHeatingBranchCirculationPump {
  On = 'on',
  Off = 'off',
  Error = 'error',
}

export enum EHeatingBranchStatus {
  On = 'on',
  Off = 'off',
  Reduced = 'reduced',
}

export enum EHeatingBranchEquithermType {
  Off = 'off',
  Equithermal = 'equithermal',
  Reduced = 'reduced',
  Const = 'const',
  Unknown = 'unknown',
}
