<div class='container login-container'>
  <div class='row mb-4'>
    <div class='offset-2 col-8 justify-content-center d-flex'>
      <p class='mat-headline-5 mb-2'>Přihlášení</p>
    </div>
  </div>

  <div class='row'>
    <div class='offset-2 col-8 justify-content-center d-flex'>
      <mat-form-field class='w-100' style='max-width: 500px'>
        <mat-label>Jméno</mat-label>
        <input [(ngModel)]='loginName' [type]="'text'" matInput>
      </mat-form-field>
    </div>
  </div>

  <div class='row'>
    <div class='offset-2 col-8 justify-content-center d-flex'>
      <mat-form-field class='w-100' style='max-width: 500px'>
        <mat-label>Heslo</mat-label>
        <input [(ngModel)]='loginPassword' [type]="'password'" matInput>
      </mat-form-field>
    </div>
  </div>

  <div class='row'>
    <div class='offset-2 col-8 justify-content-center d-flex mt-4'>
      <button [disabled]='!loginPassword || !loginName' (click)='authorize()' mat-raised-button color="primary">Přihlásit</button>
    </div>
  </div>
</div>
