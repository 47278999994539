<div style='position: relative'>
  <mat-icon class='oe-close-icon' style='position: absolute; top: 10px; right: 10px' (click)='dialogRef.close()' fontIcon="close"></mat-icon>

  <div style='position: relative; top: 50px'>
    <h3 style='text-align: center; margin-bottom: 25px'>Změna hesla</h3>

    <div style='display: flex; flex-wrap: wrap; margin: 0 30px; justify-content: space-between'>
      <mat-form-field style='width: 100%'>
        <mat-label>Staré heslo</mat-label>
        <input [(ngModel)]='user.old' [type]="'text'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 100%'>
        <mat-label>Nové heslo</mat-label>
        <input [(ngModel)]='user.new' [type]="'password'" matInput>
      </mat-form-field>

      <mat-form-field style='width: 100%'>
        <mat-label>Nové heslo pro kontrolu</mat-label>
        <input [(ngModel)]='user.newConfirm' [type]="'password'" matInput>
      </mat-form-field>

      <div [class.hidden]='user.newConfirm === user.new' class='invalid-input'>Hesla se neshodují</div>
    </div>

    <div style='display: flex; justify-content: center; margin-top: 15px'>
      <button
        (click)='dialogRef.close()'
        style='margin-right: 10px'
        mat-button color="primary">
        Zrušit
      </button>
      <button
        [disabled]='user.newConfirm !== user.new'
        (click)='dialogRef.close(user)'
        style='margin-left: 10px'
        mat-raised-button color="primary">
        Změnit
      </button>
    </div>
  </div>
</div>
