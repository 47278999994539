<div style='position: relative'>
  <mat-icon class='oe-close-icon' style='position: absolute; top: 10px; right: 10px' (click)='dialogRef.close()' fontIcon="close"></mat-icon>

  <div style='position: relative; top: 50px'>
    <h3 style='text-align: center; margin-bottom: 25px'>Přiřadit službu</h3>

    <div style='display: flex; flex-wrap: wrap; margin: 0 30px; justify-content: space-between'>
      <mat-form-field style='width: 100%'>
        <mat-label>Služba</mat-label>
        <mat-select [(value)]="value" multiple>
          <mat-option [value]="service.serviceId" *ngFor='let service of services'>{{ service.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style='display: flex; justify-content: center; margin-top: 15px'>
      <button
        (click)='dialogRef.close()'
        style='margin-right: 10px'
        mat-button color="primary">
        Zrušit
      </button>
      <button
        (click)='confirm()'
        style='margin-left: 10px'
        mat-raised-button color="primary">
        Přiřadit
      </button>
    </div>
  </div>
</div>
