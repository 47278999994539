import { Pipe, PipeTransform } from '@angular/core';
import { CS } from '../constants/table-column-headers';

@Pipe({ name: 'columnHeaderTranslate', standalone: true })
export class ColumnHeaderTranslate implements PipeTransform {
  public transform(value: string): string {
    // @ts-ignore
    return CS[value] || value;
  }
}
