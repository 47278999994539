<div style='margin: 30px 15px; min-width: max-content'>
  <div class='table-toolbar'>
    <button mat-stroked-button color="primary" (click)='openConsumptionPointHandleDialog()'>
      Přidat odběrné místo
      <mat-icon class='oe-primary-icon' fontIcon="add" style='margin-right: 6px'></mat-icon>
    </button>
  </div>
  <oe-table
    *ngIf='consumptionPointTable'
    [data]='consumptionPointTable'
    [pageSize]='10'
    [hiddenColumns]="['consumptionPointId']"
    (rowDelete)='openDeleteConfirmDialog($event)'
    (detailOpened)='_router.navigate([ERoutes.Main, ERoutes.Consumption_Point_Management, $event.consumptionPointId])'
    (rowEdit)='openConsumptionPointHandleDialog($event)'>
  </oe-table>
</div>
