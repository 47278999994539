<div style='position: relative'>
  <mat-icon class='oe-close-icon' style='position: absolute; top: 10px; right: 10px' (click)='dialogRef.close()' fontIcon="close"></mat-icon>

  <div style='position: relative; top: 50px'>
    <h3 style='text-align: center; margin-bottom: 25px'>Opravdu chcete záznam smazat?</h3>

    <div style='display: flex; justify-content: center'>
      <button
        (click)='dialogRef.close()'
        style='margin-right: 10px'
        mat-button color="primary">
        Ne
      </button>
      <button
        (click)='dialogRef.close(true)'
        style='margin-left: 10px'
        mat-raised-button color="primary">
        Ano
      </button>
    </div>
  </div>
</div>
