import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { OwlDateTimeIntl, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CalendarModule, CalendarUtils, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultIntl } from './app/shared/constants/date-time-picker-lang.constant';
import { appRoutes } from './app/shared/constants/oe-routes.constant';
import { AuthInterceptor } from './assets/auth.interceptor';

bootstrapApplication(AppComponent, {
  providers: [
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    importProvidersFrom(
      HttpClientModule,
      BrowserAnimationsModule,
      MatSnackBarModule,
      CalendarUtils,
      OwlNativeDateTimeModule,
      MatNativeDateModule,
      ToastrModule.forRoot(),
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
      RouterModule.forRoot(appRoutes)
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
}).catch(e => console.error(e));
