<oe-toolbar *ngIf="!_router.url.split('/').includes(ERoutes.Login)"></oe-toolbar>

<mat-sidenav-container>
  <mat-sidenav mode="side" [opened]="!_router.url.split('/').includes(ERoutes.Login)">
    <div class='sidenav-container'>
      <button
        mat-raised-button
        [class.active-item]="_router.url.split('/').includes(ERoutes.Dashboard)"
        (click)='_router.navigate([ERoutes.Main, ERoutes.Dashboard])'>
        Dashboard
      </button>
      <div class='divider'></div>

      <button
        mat-raised-button
        [class.active-item]="_router.url.split('/').includes(ERoutes.Consumption_Point_Management)"
        (click)='_router.navigate([ERoutes.Main, ERoutes.Consumption_Point_Management])'>
        Správa odběrných míst
      </button>
      <div class='divider'></div>

      <button
        mat-raised-button
        [class.active-item]="_router.url.split('/').includes(ERoutes.User_Management)"
        (click)='_router.navigate([ERoutes.Main, ERoutes.User_Management])'>
        Správa uživatelů
      </button>
      <div class='divider'></div>

      <button
        mat-raised-button
        [class.active-item]="_router.url.split('/').includes(ERoutes.Company_Management)"
        (click)='_router.navigate([ERoutes.Main, ERoutes.Company_Management])'>
        Správa firem
      </button>
      <div class='divider'></div>

      <button
        mat-raised-button
        [class.active-item]="_router.url.split('/').includes(ERoutes.Group_Management)"
        (click)='_router.navigate([ERoutes.Main, ERoutes.Group_Management])'>
        Správa skupin
      </button>
      <div class='divider'></div>

      <button
        mat-raised-button
        [class.active-item]="_router.url.split('/').includes(ERoutes.SVR)"
        (click)='_router.navigate([ERoutes.Main, ERoutes.SVR])'>
        Služby výkonové rovnováhy
      </button>
      <div class='divider'></div>

      <button
        mat-raised-button
        [class.active-item]="_router.url.split('/').includes(ERoutes.Heating_Branch_Management)"
        (click)='_router.navigate([ERoutes.Main, ERoutes.Heating_Branch_Management])'>
        Řízení topných větví
      </button>
      <div class='divider'></div>

      <button mat-raised-button>
        Řízení SPOT cenami
      </button>
      <div class='divider'></div>

      <button mat-raised-button>
        Řízení více zdrojů energie
      </button>
      <div class='divider'></div>

      <button mat-raised-button>
        Řízení 1/4 hodinového maxima
      </button>
      <div class='divider'></div>

      <button mat-raised-button>
        Řízení fotovoltaické elektrárny
      </button>
      <div class='divider'></div>

      <button mat-raised-button>
        Výkonová predikce větrných elektráren
      </button>
      <div class='divider'></div>

      <div style='position: absolute; bottom: 0; width: 100%'>
        <div class='divider'></div>
        <button (click)='_auth.logout()' mat-raised-button style='width: 100%'>
          Odhlásit
        </button>
      </div>
    </div>
  </mat-sidenav>
  <router-outlet></router-outlet>
</mat-sidenav-container>
