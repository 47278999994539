<div style='margin: 30px 15px; min-width: max-content; border: 1px solid red'>
  <div class='table-toolbar header' style='justify-content: space-between'>
<!--    <button mat-stroked-button color="primary" (click)='openServiceHandleDialog()'>-->
<!--      Přidat topnou větev-->
<!--      <mat-icon class='oe-primary-icon' fontIcon="add" style='margin-right: 6px'></mat-icon>-->
<!--    </button>-->
  </div>

  <div class='branch-list-container'>
    <div class='branch-wrapper' *ngFor='let branch of branches'>
      <div class='branch-header'>
        <span style='position: relative; top: 1px; padding-right: 6px'>{{ branch.name }}</span>
        <mat-icon *ngIf='!branch.freshData' style='color: #F7B217; top: 20px; position: absolute' fontIcon="warning"></mat-icon>
        <div class='value-item branch-type'>MOCK režim</div>
      </div>
      <img src='assets/images/heating-branch.png' [width]='350'>
      <div class='value-item temp-outdoor'>
        {{branch.serviceDataDetail ? branch.serviceDataDetail.tempOutdoor + ' °C' : '-'}}
      </div>

      <div class='value-item temp-out'>
        {{branch.serviceDataDetail ? branch.serviceDataDetail.tempOut + ' °C' : '-'}}
      </div>

      <div class='value-item temp-in'>
        {{branch.serviceDataDetail ? branch.serviceDataDetail.tempOut + ' °C' : '-'}}
      </div>

      <div class='value-item reg-value'>
        {{branch.serviceDataDetail ? branch.serviceDataDetail.regValve + ' %' : '-'}}
      </div>

      <div class='value-item pump'>
        <mat-icon
          *ngIf='branch.serviceDataDetail?.circulationPump !== ECirculationPump.Error && branch.serviceDataDetail'
          [fontIcon]="'refresh'"
          [class.success]='branch.serviceDataDetail.circulationPump === ECirculationPump.On'>
        </mat-icon>
        <mat-icon
          *ngIf='branch.serviceDataDetail?.circulationPump === ECirculationPump.Error || branch.serviceDataDetail === null'
          [fontIcon]="'error_outline'"
          class='error'>
        </mat-icon>
      </div>

      <div class='settings oe-primary-icon' (click)='_router.navigate([ERoutes.Main, ERoutes.Heating_Branch_Management, branch.serviceId])'>
        <mat-icon [fontIcon]="'settings'"></mat-icon>
      </div>

      <div class='toggle'>
        <mat-slide-toggle [color]="'primary'" [checked]='branch.serviceDataDetail?.status === EHeatingBranchStatus.On'></mat-slide-toggle>
      </div>
    </div>
  </div>

<!--  <div style='margin-top: 20px'>-->
<!--    <oe-table-->
<!--      *ngIf='tableSource'-->
<!--      [data]='tableSource'-->
<!--      (rowEdit)='openServiceHandleDialog($event)'-->
<!--      (rowDelete)='openDeleteDialog($event)'-->
<!--      [hiddenColumns]="['deviceId', 'type', 'companyId']"-->
<!--      (detailOpened)='_router.navigate([ERoutes.Main, ERoutes.Heating_Branch_Management, $event.id])'-->
<!--      [pageSize]='10'>-->
<!--    </oe-table>-->
<!--  </div>-->
</div>
