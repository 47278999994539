<div style='display:flex; flex-wrap: wrap; margin-top: 10px'>
  <div *ngFor='let sequence of sequences'>
    <mat-form-field style='width: 135px'>
      <mat-label>{{sequence.label}}</mat-label>
      <mat-select (selectionChange)='updateSettings()' [(value)]="sequence.value">
        <mat-option [value]="EHeatingBranchEquithermType.Off">Vypnuto</mat-option>
        <mat-option [value]="EHeatingBranchEquithermType.Const">Konstanta</mat-option>
        <mat-option [value]="EHeatingBranchEquithermType.Reduced">Mimo dům</mat-option>
        <mat-option [value]="EHeatingBranchEquithermType.Equithermal">Ekviterma</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox [color]="'primary'" (change)="updateAllChecked()" [(ngModel)]='sequence.checked' style='bottom: 7px'></mat-checkbox>
  </div>
</div>

<div>
  <mat-form-field style='width: 225px'>
    <mat-label>Hromadné nastavení</mat-label>
    <mat-select (selectionChange)='setCheckedValues($event); updateSettings()'>
      <mat-option [value]="EHeatingBranchEquithermType.Off">Vypnuto</mat-option>
      <mat-option [value]="EHeatingBranchEquithermType.Const">Konstanta</mat-option>
      <mat-option [value]="EHeatingBranchEquithermType.Reduced">Mimo dům</mat-option>
      <mat-option [value]="EHeatingBranchEquithermType.Equithermal">Ekviterma</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox [indeterminate]="someComplete" (change)="setAll($event.checked)" [color]="'primary'" style='bottom: 7px'></mat-checkbox>
</div>
