import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { ERoutes } from '../../enums/oe-routes.enum';
import { EToolbarHeader } from '../../interfaces/state.interface';
import { EToastType } from '../../interfaces/toast.interface';
import { IUser } from '../../interfaces/user.interface';
import { StateService } from '../../services/state.service';
import { ToasterService } from '../../services/toaster.service';
import { UserApiService } from '../../services/user-api.service';
import { OeDeleteConfirmDialogComponent } from '../oe-delete-confirm-dialog/oe-delete-confirm-dialog.component';
import { OeTableComponent } from '../oe-table/oe-table.component';
import { OeUserHandleDialogComponent } from '../oe-user-handle-dialog/oe-user-handle-dialog.component';

@Component({
  standalone: true,
  selector: 'oe-user-management',
  providers: [HttpClient],
  templateUrl: './oe-user-management.component.html',
  styleUrls: ['./oe-user-management.component.scss'],
  imports: [MatButtonModule, MatIconModule, MatSortModule, MatTableModule, NgIf, OeTableComponent],
})
export class OeUserManagementComponent implements OnInit {
  // INJECTS
  private _state = inject(StateService);
  private _user = inject(UserApiService);
  private _router = inject(Router);
  private _dialog = inject(MatDialog);
  private _toast = inject(ToasterService);

  // VARIABLES
  public tableSource!: any;

  constructor() {
    this._state.header = EToolbarHeader.User_Management;
  }

  ngOnInit() {
    this.setupTable();
  }

  public viewUserDetail(user: IUser) {
    this._state.user = user;
    void this._router.navigate([ERoutes.Main, ERoutes.User_Management, user.userId]);
  }

  public openDeleteDialog(user: IUser) {
    this._dialog
      .open(OeDeleteConfirmDialogComponent, {
        height: '200px',
        width: '400px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._user
            .deleteUser(user.userId as string)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.setupTable();
                this._toast.open(EToastType.Success, 'Uživatel byl úspěšně smazán.');
              },
              error: err => {
                this._toast.open(EToastType.Danger, err.error.message || 'Uživatele nebylo možné smazat.');
              },
            });
        }
      });
  }

  public openUserHandleDialog(user?: IUser) {
    this._dialog
      .open(OeUserHandleDialogComponent, {
        height: user ? '350px' : '420px',
        width: '780px',
        data: user,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (!user) {
            this._user
              .uploadUser(result)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setupTable();
                  this._toast.open(EToastType.Success, 'Uživatel byl úspěšně vytvořen.');
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Uživatele nebylo možné vytvořit.');
                },
              });
          } else {
            this._user
              .editUser({ ...result, userId: user.userId })
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.setupTable();
                  this._toast.open(EToastType.Success, 'Uživatel byl úspěšně upraven.');
                },
                error: err => {
                  this._toast.open(EToastType.Danger, err.error.message || 'Uživatele nebylo možné upravit.');
                },
              });
          }
        }
      });
  }

  private setupTable() {
    this.tableSource = null;

    this._user
      .getUserList()
      .pipe(take(1))
      .subscribe(response => {
        this.tableSource = response.list.map(user => {
          return {
            userIcon: true,
            userId: user.userId,
            name: user.username,
            role: user.role,
            mail: user.email,
            detail: true,
            edit: true,
            remove: true,
            password: user.password,
            companyId: user.companyId,
          };
        });
      });
  }
}
